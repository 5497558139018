import { useState, useEffect, FC } from 'react';
import axios, {AxiosResponse} from 'axios';
import { Image, Spin, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
//import { setConstantValue } from 'typescript';
//const { Panel } = Collapse;

const { Option } = Select;
 
const Innermenu: FC<{slug: string, menuactive:string, confilter: boolean}> = ({children, slug, menuactive, confilter}) => {
 
  let [collection, setCollection] = useState([]);
  let [collectionimg, setCollectionimg] = useState("");
  let [searchCollection, setsearchCollection] = useState([]);
  let [collectiontemp, setcollectiontemp] = useState([]);
  
  const [collectiontoken, setcollectiontoken] = useState("");
  const [collectionowners, setcollectionowners] = useState("");
  const [collectionfloorsol, setcollectionfloorsol] = useState(0);
  const [rates, setRates] = useState(0);
  const { collectionID } = useParams();;
  
  const [loading, setloading] = useState(true);
  //const [Attributes, setAttributes] = useState<any>([]);

  useEffect(() => { 
    getData();
    getRates();
    getCollectionData();
  }, []); 

  const getRates = async () => {
    await axios.get(process.env.REACT_APP_API_COIN+"").then(
      (res: AxiosResponse) => {
        setRates(res.data.market_data.current_price.usd);
      }
    ).catch((e: Error) => {
      console.log(e);
    }); 
  };

  const getData = async () => {
    setloading(true);   

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        let value = res.data.data.attributes;
         
        //setAttributes(value); 
        //setloading(false);
        setCollection(res.data.data.name);
        setCollectionimg(res.data.data.image);
        setcollectiontoken(res.data.data.supply_total);
        setcollectionowners(res.data.data.owners_total);
        setcollectionowners(res.data.data.owners_total);
        setcollectionfloorsol(res.data.data.floor_sol);
      }
    ).catch((e: Error) => { 
      console.log(e);
    }); 
  };
   

  const getCollectionData = async () => {
    setloading(true);
    

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/summary",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(async(res: AxiosResponse) => {     
        await setsearchCollection(res.data.data);
        setTimeout(()=>{
          setloading(false);   
        },2000); 
      }
    ).catch((e: Error) => {
      console.log(e);
    }); 
  };
    


  const priceFormat = (amount: any) => {
    var price = Math.round(amount).toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
     minimumFractionDigits:0
   });
   return price;
   }

   const closeImg = (
    <Image src='../../images/close.svg' preview={false} className="clost-btn" alt='close' />
   );

// handle change event of search input
const handleChange = (value: any) => {

  filterData(value);
  

};


  const filterData = async(value: any) => {

    const lowercasedValue = value.toLowerCase().trim();
    let result:any = [];
    console.log(lowercasedValue);
    console.log(lowercasedValue.length);
    if (lowercasedValue.length>0){
      await searchSummary(lowercasedValue);
    }else {
      getData();
  }
  };


  const searchSummary = async (value:any)=>{

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+`collections/search?q=${value}`,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        setsearchCollection(res.data.data);
      }
    ).catch((e: Error) => {
      console.log(e);
    });

}

   
  return(
    <>
    <div className="mob-search">
      <div id="mrp-box" className="desk-sech">
          <Select className={`search-int ${loading ? 'load': ''}`} showSearch showArrow={false} onSearch={(e:any)=>handleChange(e)} notFoundContent={(loading?<><div className="spin"><Spin size="large" /></div></>:<><div className="drp-flx no-data">
                <Image preview={false}  src={"../images/no-image.jpg"} />
                <span>No results found</span> 
              </div></>)} getPopupContainer={() => document.getElementById("mrp-box")} style={{ width: '100%' }} virtual={true} placeholder="Search Collections" >
              {searchCollection.map((item: any,index:any)=>{ return (<Option value={item.name} key={index+100}>
              <div className="drp-flx" onClick={()=> {window.location.href ="/overview/"+item.slug} }>
                <Image preview={false}  placeholder={<Image preview={false} src={item.image} />}  src={item.image} />
                <span>{item.name}</span>  
              </div> 
            </Option>)})}  
          </Select>
          <SearchOutlined />
         </div>
    </div>
    <div className="table-token-row">
      <div className="table-lists alltokens-tablelist">
      <Image src={collectionimg} preview={false} width={64} /> <h2>{collection}</h2>
      </div>

      <div className="table-cnt-list">
      {loading === false &&   (
        <>
        <div className="table-list-innr">
          <p>Tokens</p>
          <h5>{collectiontoken}</h5>
        </div>
        <div className="table-list-innr">
          <p>Owners</p>
          <h5>{collectionowners}</h5>
        </div>
        <div className="table-list-innr">
          <p>Floor Price</p>
          <h5>{collectionfloorsol} SOL ({priceFormat(rates*collectionfloorsol)})</h5>
        </div>
        </>
        )}
        {loading === true &&  <Spin size="large" />}
      </div>
    </div>
      <div className={`tokenholder-split ${confilter === true ? 'show-filter' : ''}`}>
        <div className="tokenholders-menu">
          <Link to={"/overview/"+slug} className={menuactive==='overview'?"next-link-exact-active next-link-active":""} >Overview</Link> 
          <Link to={"/listedtokens/"+slug} className={menuactive==='listedtokens'?"next-link-exact-active next-link-active":""}>Listed Tokens</Link> 
          <Link to={"/tokenholders/"+slug} className={menuactive ==='tokenholders'?"next-link-exact-active next-link-active":""}>Holders</Link> 
          <Link to={"/alltokens/"+slug} className={menuactive ==='alltokens'?"next-link-exact-active next-link-active":""}>All Tokens</Link> 
          {/* <a href={"/floor-insights/"+slug} className={menuactive ==='floor-insights'?"next-link-exact-active next-link-active":""}>Floor <strong>INSIGHTS</strong></a>  */}
          <Link to={"/rarities/"+slug} className={menuactive ==='rarities'?"next-link-exact-active next-link-active":""}>Rarity</Link>
        </div>
        
      </div>
      
    </>
);

  }

export default Innermenu; 