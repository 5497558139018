import React, { useState, useEffect, FC, memo } from "react";

import { TinyArea } from '@ant-design/charts';

const Circulating:FC<{ solanacirdata: [], loadingval: boolean, fillVal: any}> = ({children,  solanacirdata, loadingval, fillVal}) => {
  

  const priceFormatnew = (amount: any) => {

    if(amount > 999 && amount < 1000000){
      return "$"+(amount/1000).toFixed(2) + ' K'; // convert to K for number from > 1000 < 1 million 
    }else if(amount > 1000000 && amount < 1000000000){
      return "$"+(amount/1000000).toFixed(2) + ' M'; // convert to M for number from > 1 million 
    }else if(amount > 1000000000 && amount < 1000000000000){
      return "$"+(amount/1000000000).toFixed(2) + ' B'; // convert to M for number from > 1 million 
    }else if(amount < 1000){
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });; // if value < 1000, nothing to do 
    } 
 

   }



    const data = solanacirdata;
      const config = {
        height: 30,
        autoFit: true,
        data,
        smooth: true,
        tooltip: {
          customContent: (title: any, items: any[]) => {
            const data = items[0]?.data || {};
            
            return `<div class="chart-tooltip">${priceFormatnew(data.y)}</div>`;
          }
        }, 
        areaStyle: {
          fill: fillVal,
        },
        line: {
          color: '#8ec8ff',
        }
      };
    return(
        <>
        <TinyArea {...config} />
        </>
    );
}
export default memo(Circulating);
