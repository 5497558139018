import { FC, useEffect, useState, memo, useCallback } from "react";

import {  PublicKey } from "@solana/web3.js";
import axios, { AxiosResponse } from "axios";
import nacl from 'tweetnacl';
import { Button } from 'antd';
type PhantomEvent = "disconnect" | "connect" | "accountChanged";

interface ConnectOpts {
    onlyIfTrusted: boolean;
}

interface PhantomProvider {
    connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
    disconnect: () => Promise<void>;
    on: (event: PhantomEvent, callback: (args: any) => void) => void;
    signMessage: (encodedMessage: Uint8Array, char: string) => Promise<Uint8Array>;
    isPhantom: boolean;
}

type WindowWithSolana = Window & {
    solana?: PhantomProvider;
}

const Connect2Phantom: FC<{ showconnect: boolean, showdisconnect: boolean, setshowConnectSuccess: (arg: boolean) => void, setshowConnectError: (arg: boolean) => void, setshowModal: (arg: boolean) => void }> = ({ children, showconnect, showdisconnect, setshowConnectSuccess, setshowConnectError, setshowModal }) => {

    const [walletAvail, setWalletAvail] = useState(false);
    const [provider, setProvider] = useState<PhantomProvider | null>(null);
    const [connected, setConnected] = useState(false);
    
    const checkSolana = ()=>{
        var logintoken = localStorage.getItem('logintoken');
        
        if (logintoken !== "" && logintoken !== null && logintoken !== undefined) {
            setConnected(true);
        }
            if ("solana" in window) {
                console.log("inside solana"); 
                const solWindow = window as WindowWithSolana;
                if (solWindow?.solana?.isPhantom) {
                    console.log("inside phantom");
                    setProvider(solWindow?.solana);
                    setWalletAvail(true);
                    // Attemp an eager connection
                    // solWindow?.solana?.connect();
                } else {
                    setWalletAvail(false); 
                }
            } else {
                setWalletAvail(false);
            }
       
    }

    useEffect(() => {

        checkSolana();
         
    },[]);

    async function loginUsingPublicKey(publicKey: PublicKey, pubkeyvalue: string): Promise<any> {
        var message = "Sign in request.\r\n\r\n" +
            "No password is needed, just sign/approve this message to confirm ownership of this wallet.\r\n\r\n" +
            "There will be no transaction nor gas fees associated with this request.\r\n\r\n" +
            publicKey;

        var encodedMessage = new TextEncoder().encode(message);
        return await provider.signMessage(encodedMessage, "utf8")
            .then(function (obj) {


                // @ts-ignore
                var verified = nacl.sign.detached.verify(encodedMessage, obj.signature, obj.publicKey.toBytes());

                if (!verified) {
                    console.log('Couldn\'t verify signed message.');
                    return false;
                }

                let URL = process.env.REACT_APP_API_BASE_URL + "login";


                // @ts-ignore
                let bytes = btoa(obj.publicKey.toBytes().toString('hex'));
                // @ts-ignore
                let sign = btoa(obj.signature.toString('hex'));


                //let data = {"pubkey": pubkeyvalue,"bytes":bytes,"sign":sign};
                var data = JSON.stringify({
                    "publicKey": pubkeyvalue,
                    "bytes": bytes,
                    "signature": sign
                });

                axios({
                    method: 'post',
                    url: URL,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                })
                    .then(function (response) {

                        if (response?.data?.success === true) {
                            axios({
                                method: 'get',
                                url: process.env.REACT_APP_API_BASE_URL + "collections/summary",
                                headers: {
                                    'Authorization': 'Bearer ' + response?.data?.data?.token
                                }
                            }).then((res: AxiosResponse) => {
                                if (res.data.success === true) {
                                    localStorage.setItem('logintoken', response?.data?.data?.token);
                                    setshowConnectSuccess(true);
                                    setTimeout(function () {
                                        setConnected(true);
                                        setshowModal(false);
                                        window.location.href = '/'
                                    }, 3000);
                                }else {
                                    setshowConnectError(true);        
                                }
                            }).catch((e: Error) => { 
                                setshowConnectError(true);  
                            })

                        } else {
                            setshowConnectError(true);

                        }
                    }).catch(function (error) {
                        setshowConnectError(true);
                        console.log(error);
                    });


                return true;
            });
    }

    const loginconnect = useCallback(() => {
        provider?.on("connect", (publicKey: PublicKey) => {
            //console.log(`connect event: ${publicKey}`);
            var pubkeyval = `${publicKey}`;

            loginUsingPublicKey(publicKey, pubkeyval).then(
                (res: any) => {
                    console.log("response aya?");
                    console.log(res);
                }
            ).catch((e: Error) => {
                console.log(e);
            });

        });
    },[provider]);


    const logindisconnect = useCallback(() => {
        provider?.on("disconnect", () => {
            console.log("disconnect event");
            setConnected(false);
            // To clear a specific item
            //localStorage.removeItem('logintoken');

        });
    }, [provider]);


    useEffect(() => {

        logindisconnect();
        loginconnect();

    }, [logindisconnect, loginconnect]);

    const connectHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        
        const solWindow = window as WindowWithSolana;
        
        if (solWindow?.solana?.isPhantom) {
            solWindow?.solana?.connect().catch((err) => {
                setshowConnectError(true);
            });
        }else{
            
        }
    }

    const disconnectHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {

        provider?.disconnect()
            .catch((err) => {
                console.error("disconnect ERROR:", err);
            });
        localStorage.removeItem('logintoken');
        window.location.href = "/login";
    }

    return (
        <div>
            {walletAvail ?
                <>
                    {!connected && showconnect && <Button type="primary" onClick={connectHandler}>Connect</Button>}
                    {connected && showdisconnect && <div className="dis_btn_holder">
                        <Button className="disconnect-btn" type="primary" onClick={disconnectHandler}>Disconnect</Button>
                    </div>}
                    {/*connected ? <p>Your public key is : {pubKey?.toBase58()}</p> : null*/}
                </>
                :
                <>
                    <p>Opps!!! Phantom is not available. Go get it <a
                        href="https://phantom.app/">https://phantom.app/</a>.</p>
                </>
            }
        </div>
    );
}

export default memo(Connect2Phantom);