import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.less';
 
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Home from "./components/home";
import Dashboard from "./components/dashboard";
// import BetaOverview from "./components/dashboard/overview";
import Listedtokens from "./components/listedtokens";
import Tokenholders from "./components/tokenholders";
import SubToken from "./components/tokenholders/subtoken";
import Alltokens from "./components/alltokens";
import Rarities from "./components/rarities";
import Floorinsights from "./components/floorinsights";
import TokenScroll from "./components/tokenscroll";
import Pages from "./components/pages";
import Login from "./components/login";



ReactDOM.render(
  <React.StrictMode>
     <BrowserRouter>
     <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="listedtokens/:collectionID" element={<Listedtokens />} />
      <Route path="tokenholders/:collectionID" element={<Tokenholders />} />
      <Route path="subtoken/:collectionID/:tokenHolderId" element={<SubToken />} />
      <Route path="alltokens/:collectionID" element={<Alltokens />} />
      <Route path="overview/:collectionID" element={<Dashboard />} />
      {/* <Route path="beta-overview/:collectionID" element={<BetaOverview />} /> */}
      <Route path="rarities/:collectionID" element={<Rarities />} />
      <Route path="floor-insights/:collectionID" element={<Floorinsights />} />
      <Route path="/:pageslug" element={<Pages />} />
      <Route path="tokenscroll/:collectionID" element={<TokenScroll />} />
    </Routes>
  </BrowserRouter> 
  </React.StrictMode>, 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();   