import useLocalStorage from 'react-use-localstorage';
import React, { useState, useEffect, FC } from "react";
import axios, {AxiosResponse} from 'axios';
import '../../App.less';
import './dashboard.less';
import './overview.less';
import { useParams,Link } from 'react-router-dom';
import { Layout, Row, Col, Table, Spin,  message} from 'antd';
import {Scatter } from '@ant-design/charts';
import {   CopyOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
import Innermenu from "../layout/innermenu";
import TokenChart from "./tokenchart/index";
import Ownerchart from "./ownerchart/index";
import Pricechart from "./pricechart/index";
import Tokenowner from "./perownerchart/index";
import Aggregatedchart from "./aggregatedchart/index";
import Pricedistribution from "./pricedistribution/index";
import {getDate} from '../helper'

const { Content } = Layout;
//const style = { background: '#0092ff', padding: '8px 0' };
//const { TabPane } = Tabs;
 

export const CollectionContext = React.createContext<any>([]);
 
  const BetaOverview:FC = () => {
  //const [loadingagg, setloadingagg] = useState(true);
  const [loading, setloading] = useState(true);
  const [colLoad, setColLoad] = useState(false);
  const { collectionID } = useParams(); 
  const [collection, setcollection] = useState<any>([]);
  const [toggleNav, setToggleNav] = useState(false);

const [topdata, setTopdata] = useState([]);
const [topowner, setTopowner] = useState([]);
const [topOwnerWhalePercent, settopOwnerWhalePercent] = useState(0);


const [collectionfloorsol, setcollectionfloorsol] = useState("");
const [collectionmediansol, setcollectionmediansol] = useState("");

const [ownersLastUpdate, setownersLastUpdate] = useState("");
const [pricesLastUpdate, setpricesLastUpdate] = useState("");

const [rates, setRates] = useState<any>();

const [activatekey, setactivatekey] = useState("1");

const [loadChart, setloadChart] = useState(false);

  useEffect(() => { 
    getData();
    listData();
  },[]);

  /* Dark Mode -- START */

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    }

    var rootElement = document.getElementsByTagName('html')[0];
    // let darkvar = 'dark';
    // let lightvar = 'light';
    rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
    // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
    // console.log(`Dark Mode: ${props.darkmodeTog}`);
    // {props.darkmodeTog}
    // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):false);

  const [darkmode, setDarkmode] = useState(false);
  useEffect(() => {
    if (document.getElementsByTagName('html')[0].classList.contains('dark')) {
      setDarkmode(true);
    } else {
      setDarkmode(false);
    }
  }, []);

  /* Dark Mode -- END */

  const priceFormat = (amount: any) => {
    var price = Math.round(amount).toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
     minimumFractionDigits:0
   });
   return price;
   }

  const getData = async () => {
    

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        setloading(false); 
        setcollection(res.data?.data)
        setownersLastUpdate((res.data.data.hasOwnProperty("owners_last_updated")?res.data.data.owners_last_updated:""));
        setpricesLastUpdate((res.data.data.hasOwnProperty("prices_last_updated")?res.data.data.prices_last_updated:""));
        setcollectionfloorsol(res.data.data.floor_sol);
        setcollectionmediansol(res.data.data.median_sol);
      }
    ).catch((e: Error) => {
      console.log(e);
    }); 

    await axios.get(process.env.REACT_APP_API_COIN+"").then(
      (res: AxiosResponse) => {
        
        setloading(false);
        setRates(res.data.market_data.current_price.usd);
       
      
      }
    ).catch((e: Error) => {
      console.log(e);
    }); 


  };
  
  const listData = async () => {    

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/token-owners-percentage",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {

        settopOwnerWhalePercent(res.data.meta.whale_percentage);

        setTopdata(res.data.data.map((value: any, key: number) =>({
          key:key+1, 
          tokens: value.total_tokens,
          qty: value.total_owners,
          percentage: (value.percentage).toFixed(2)+'%',
    })));
      } 
    ).catch((e: Error) => {
      console.log(e); 
    }); 

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/top-10-owners",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {

        setTopowner(res.data.data.map((value: any, key: number) =>({
          key:key+1, 
          address: value.owned_by,
          tokens: value.total_tokens,
    })));
      } 
    ).catch((e: Error) => {
      console.log(e);
    });     

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/price-vs-ranks",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
 
        setScdata(res.data.data);
      } 
    ).catch((e: Error) => {
      console.log(e);
    });


  }

  /* Scatter Start */
  const [scdata, setScdata] = useState<any>([]);
  //const tipColor = '#5b8ff9';
  const scattconfig = {
    data: scdata,
    height: 400,
    xField: 'rank', 
    yField: 'price',
    colorField: 'marketplace',
    size: 5,
    shape: 'circle',
    legend:{},
    pointStyle: {
      fillOpacity: 1,
    },
    brush: { 
      enabled: true,
      mask: {
        style: {
          fill: 'rgba(255,0,0,0.15)',
        },
      },
    },
    yAxis: {},
    xAxis: {},
    tooltip: { 
      customContent: (title: any, items: any[]) => {
          
        const data = items[0]?.data || {}; 
        //const dataImg = data.image_uri;
        return `
            <div class="chart-tooltip">
              <h2>${title}</h2> 
              <div class="chart-val">
                <span class="chart-img">
                  <img src=${data.image_uri}  alt=${data.marketplace} />
                </span>
                <div class="chart-cnt"> 
                  <p>Rank: ${data.rank}</p>
                  <p>Price: ${data.price}</p>
                  <p>Market Place: ${data.marketplace}</p>
                  <p><a target="_blank" href=${data.buy_link} class="buy-btn">Buy Now</a></p>
                </div>   
              </div>    
            </div> 
        `;
      }, 
      enterable: true, 
      shared: false,
      offset: -2,
      style: {
        paddingTop: 20,
      }
    },
    // quadrant: {},
  }; 

  scattconfig.legend = { layout: 'horizontal', position: 'top', flipPage: false, offsetY: -9, itemHeight: 35, maxHeight: 200};
  scattconfig.yAxis = {type: 'log', label: {style: {fontWeight: 600}}};
  scattconfig.xAxis = {label: {style: {fontWeight: 600}}}; 
  // scattconfig.quadrant = { xBaseline: 0, yBaseline: 0, regionStyle: [{fill: '#f8f8f8',fillOpacity: 1,},],};
  /* Scatter End */ 

  /* Column End */   

  const columns = [
    {
      title: '# of tokens',
      dataIndex: 'tokens',
      key: 'tokens',
      sorter: (a: any, b: any) => a.tokens-b.tokens,
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      sorter: (a: any, b: any) => a.qty-b.qty,
    },
    { 
      title: '%',
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a: any, b: any) => a.percentage.localeCompare(b.percentage),
    },
  ]

  const topColumns = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      className:'cpy',
      sorter: (a: any, b: any) => a.address.localeCompare(b.address),
      render: (text: string) => <><span onClick={() => {

        if ('clipboard' in navigator) {
          navigator.clipboard.writeText(text);
          message.info('Copied to clipboard');
        } else {
           document.execCommand('copy', true, text);
           message.info('Copied to clipboard');
        }
 

     }}><CopyOutlined /></span>
     <Link to={`/subtoken/${collectionID}/${text}`}>
        {text.slice(0,6)+"..."+text.slice(-6)}
        </Link>
     </>,
    },

    
    {
      title: '# of tokens',
      dataIndex: 'tokens',
      key: 'tokens',
      sorter: (a: any, b: any) => a.tokens-b.tokens,
    },
  ]

  // const boxToggle = () => {
  //   setloading(true)
  //   setColLoad(true)
  //   setTimeout(()=> {
  //     setloading(false)
  //     setColLoad(false)
  //     setToggleNav(!toggleNav);
  //   }, 2000)
  // }
  
  const colWidth = toggleNav === true ? 24 : 12;

  return( <CollectionContext.Provider value={{collection}}><Layout className="layout">
    <HeaderC toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />  
      
     <Content style={{ padding: '50px 50px' }}> 
      <div className="token-row dash-row">
        <Innermenu slug={""+collectionID} menuactive={"overview"}   confilter={false} />	
        <div className="token-filter">
        <div className={`filter-nav ${toggleNav === false && 'active'}`}  onClick={() => {setloading(true);setColLoad(true);setTimeout(()=> {setloading(false);setColLoad(false);setToggleNav(false)})}}><FullscreenExitOutlined /> Compact View</div>
          <div className={`filter-nav ${toggleNav === true && 'active'}`} onClick={() => {setloading(true);setColLoad(true);setTimeout(()=> {setloading(false);setColLoad(false);setToggleNav(true)})}}><FullscreenOutlined /> Detail View</div>
        </div>
      </div>
   
  <div className={`chart-row ${toggleNav === false ? 'rev-row' : 'no-rev'}`}>
  <Row className={`${colLoad === true ? 'rev-cen' : 'rev-no'} ${loading === true ? 'load-rev' : 'load-no'} ${loadChart===true?'dis-tab':""}`}>
  {loading === true &&  <Spin size="large" />}
  {
    loading === false && 
    <>
      <Col xs={24} sm={24} md={24} lg={colWidth}>
      <div className="chart-innr">
     <div className="chart-txt">
              <div className="chart-left"> 
                <h5>Price</h5>
              </div>  
            </div>
       
     <Pricechart labelVal={darkmode === true ? '#fff' : '#aaa'} activeKey={activatekey} setactivekey={setactivatekey} loadingChart={loadChart} setLoadingChart={setloadChart}  currentfloor={collectionfloorsol!=="" && rates !=="" && priceFormat(Number(collectionfloorsol)*rates)!=='$NaN' && <> {collectionfloorsol} SOL ({priceFormat(Number(collectionfloorsol)*rates)})</>} currentavg={collectionmediansol!=="" && rates !=="" && priceFormat(Number(collectionmediansol)*rates)!=='$NaN' &&<>{collectionmediansol} SOL ({priceFormat(Number(collectionmediansol)*rates)})</>} />
      {pricesLastUpdate!=="" && <h5 className="pt-10">Price Last Updated: <b>{getDate(pricesLastUpdate)}</b></h5>}
      </div>
      </Col> 

      {/* Single Line Start */}
      <Col xs={24} sm={24} md={24} lg={colWidth}>
      <div className="chart-innr">
        
        <div className="chart-txt">
              <div className="chart-left">
                <h5>Number of Tokens Listed</h5>
              </div>
            </div>
           <TokenChart activeKey={activatekey} setactivekey={setactivatekey} loadingChart={loadChart} setLoadingChart={setloadChart}  />
           {pricesLastUpdate!=="" && <h5 className="pt-10">Price Last Updated: <b>{getDate(pricesLastUpdate)}</b></h5>}
      </div> 
      </Col>
      <Col xs={24} sm={24} md={24} lg={colWidth}>
      <div className="chart-innr">
      <div className="chart-txt">
              <div className="chart-left">
                <h5>Number of Owners</h5> 
              </div>
            </div>            
     <Ownerchart  activeKey={activatekey} setactivekey={setactivatekey} loadingChart={loadChart} setLoadingChart={setloadChart}/>
     {ownersLastUpdate!=="" && <h5 className="pt-10">Owners Last Updated: <b>{getDate(ownersLastUpdate)}</b></h5>}
     
      </div> 
      </Col> 

     
      {/* Single Line End */}

      {/* Scatter Start */} 
      <Col xs={24} sm={24} md={24} lg={colWidth}>
        <div className="chart-innr chart-scatter left">
        <div className="chart-txt">
              <div className="chart-left">
                <h5>Price vs rank</h5>
              </div>
            </div>
            {scdata.length > 0 &&
            <div className="chart-srtTxt chart-sct chart-trans chart-scat">
              <p className="chart-horz">Price in SOL</p>
              <div className="chart-srt">
                 <Scatter {...scattconfig} />
              </div>
              <p className="chart-vert">Ranking</p>
              {pricesLastUpdate!=="" && <h5>Price Last Updated: <b>{getDate(pricesLastUpdate)}</b></h5>}    
            </div>}
            { scdata.length < 1 &&  <Spin size="large" /> }
        </div> 
      </Col> 
      {/* Scatter End */}

      {/* Column Start */}
      <Pricedistribution stroval={darkmode === true ? '#7d7d7d' : '#000'} darkEnb={darkmode === true ? 'dark' : 'light'} vallabel={darkmode === true ? '#7d7d7d' : '#000'} opaval={darkmode === true ? 1 : 0.4} colwidthDyn={colWidth}  loadingval={loading} />
      <Col xs={24} sm={24} md={24} lg={colWidth} className="chart-rev-full">
        <Tokenowner fillVal={darkmode === true ? '#7d7d7d' : '#000'} opaval={darkmode === true ? 1 : 0.6} />
      </Col>
 
      {/* Owners Per quantity start */}
      <Col xs={24} sm={24} md={24} lg={12} className="chart-rev-innr">
        <div className="chart-innr chart-nobk left">
          <div className="chart-txt mg-10">
              <h5>% Owners Per Quantity</h5>
          </div>
          {topdata.length > 0 && <Table dataSource={topdata} pagination={ {  defaultPageSize: 25, hideOnSinglePage: true }}  columns={columns} scroll={{ x:'100%' }} />}
          { topdata.length < 1 &&  <Spin size="large" /> }
          {topOwnerWhalePercent!==0 && <h5 className="pt-10">Whale Holdings: <b>{topOwnerWhalePercent}%</b></h5>}
          {ownersLastUpdate!=="" && <h5 className="px-7">Owners Last Updated: <b>{getDate(ownersLastUpdate)}</b></h5>}
          {/* <Table dataSource={dataSource} columns={columns} /> */}
        </div>
      </Col>
      {/* Owners Per quantity end */}

      {/* Top Owners start */}
      <Col xs={24} sm={24} md={24} lg={12} className="chart-rev-innr"> 
        <div className="chart-innr chart-nobk left">
          <div className="chart-txt mg-10">
              <h5>Top 10 owners</h5>
          </div>
          {topowner.length > 0 && <Table dataSource={topowner} columns={topColumns} pagination={ {  defaultPageSize: 25, hideOnSinglePage: true }}  scroll={{ x:'100%' }} />}
          { topowner.length < 1 &&  <Spin size="large" /> }
          {ownersLastUpdate!=="" && <h5 className="pt-10">Owners Last Updated: <b>{getDate(ownersLastUpdate)}</b></h5>}
          {/* <Table dataSource={dataSource} columns={columns} /> */}
        </div>
      </Col>
      {/* Top Owners end */}

      {/* Aggregated Chart Start */}
      <Col xs={24} sm={24} md={24} lg={colWidth} className="chart-rev-full">
      <Aggregatedchart  activeKey={activatekey} setactivekey={setactivatekey} loadingChart={loadChart} setLoadingChart={setloadChart}/>
        
      </Col>
      {/* Aggregated Chart End */}
    </>
  }
 
       
    </Row>
  </div>
 
     
  </Content>
  <Footerc />
    {/* <BackTop>
      <div style={style}>UP</div>
    </BackTop> */}
</Layout></CollectionContext.Provider>);

  }

export default BetaOverview;      