import useLocalStorage from 'react-use-localstorage';
import { useState, useEffect, FC } from "react";
import axios, {AxiosResponse} from 'axios';
import { useParams } from 'react-router-dom';
//import ReactPaginate from 'react-paginate';
import parse from 'html-react-parser';
import { Layout, Row, Col,Typography } from 'antd';
import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
//import { Attribute } from "@antv/attr";

const { Content } = Layout;
const { Title } = Typography;
//const { Paragraph } = Typography;


 
  const Pages:FC = () => {


    const [pagecontent, setpagecontent] = useState("");
    const [pagetitle, setpagetitle] = useState("");
    const { pageslug } = useParams();

    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    }

    var rootElement = document.getElementsByTagName('html')[0];
    // let darkvar = 'dark';
    // let lightvar = 'light';
    rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
    // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
    // console.log(`Dark Mode: ${props.darkmodeTog}`);
    // {props.darkmodeTog}
    // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):fal

    useEffect(() => { 
        getContent();
      }, []);
       
    
      const getContent = async () => {
    
        await axios.get(process.env.REACT_APP_API_CMS+"cmsapi?type=page&info="+pageslug).then(
          (res: AxiosResponse) => {
             
            if(res.data.status==='success'){
                setpagecontent(res.data.content.page_description); 
                setpagetitle(res.data.content.page_title); 
            }else{
                setpagecontent("Page not found"); 
                setpagetitle("404"); 
            }
            
              
          }
        ).catch((e: Error) => { 
          console.log(e); 
        }); 
         
    
    
      };

      
  return( <Layout className="layout">
    <HeaderC toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />
  <Content style={{ padding: '50px 50px' }}>
    <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
            <Title>{pagetitle}</Title>
            {parse(pagecontent)}
            
        </Col>
        
    </Row>
  </Content>
  <Footerc />
</Layout>);

  }

export default Pages; 