import useLocalStorage from 'react-use-localstorage';
import React, { useState, useEffect, FC, useCallback } from "react";
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';


import { Layout, Collapse, Image, message, Col, Table, Spin, Row, Button, Modal, Tooltip, Select } from 'antd';
import { ArrowLeftOutlined, DownOutlined, CopyOutlined } from '@ant-design/icons';
import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
import Innermenu from "../layout/innermenu";



import './alltokens.less';

const { Panel } = Collapse;


const { Header, Footer, Content, Sider } = Layout;



const Alltokens: FC = () => {


  const [state, setstate] = useState([]);
  let [collectionimg, setCollectionimg] = useState("");
  let [collection, setCollection] = useState([]);
  let [tokens, setTokens] = useState<any>([]);
  let [tokenstemp, setTokenstemp] = useState<any>([]);  
  const [Attributes, setAttributes] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(true);
  const [column, setcolumn] = useState<any>([]);
  const [rates, setRates] = useState<any>();
  const [Meta, setMeta] = useState<any>();
  const { collectionID } = useParams();
  const [filterenable, setFilterenable] = useState(0);
  const [winwidth, setwinwidth] = React.useState(window.innerWidth); 

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  }

  var rootElement = document.getElementsByTagName('html')[0];
  // let darkvar = 'dark';
  // let lightvar = 'light';
  rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
  // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
  // console.log(`Dark Mode: ${props.darkmodeTog}`);
  // {props.darkmodeTog}
  // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):false);
 
  const updateWidthAndHeight = () => {
    setwinwidth(window.innerWidth);
  };

  let scrollBar = {}
  if(winwidth > 520) {
    scrollBar = {
      x: 1300, y: 750
    }
  } else {
    scrollBar = {
      x: 1300
    }
  }

  let columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: 'name',
      width: 90,
      fixed: 'left',
      className: 'fix-table',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },

    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 55,
      render: (text: string) => <Tooltip placement="rightTop" arrowPointAtCenter={true} autoAdjustOverflow={true} title={
        <>
          <Image preview={false} src={text} />
        </>
      } overlayClassName="zoom-prv prv-right"><Image preview={false} src={text} placeholder={<Image  
      preview={false} 
      src={theme === 'dark' ? "../images/dark-no-image.jpg" : "../images/no-image.jpg"}    
      width={36}  
    />} /></Tooltip>,
      fixed: 'left',
      className: 'fix-table',  
    },
    { 
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: 100,
      sorter: (a: any, b: any) => a.rank - b.rank,
    },
  ];

  useEffect(() => {
    getColumns();

  }, []);

  let columnlist: any = [];



  const priceFormat = (amount: any) => {
    var price = Math.round(amount).toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
     minimumFractionDigits:0
   });
   return price;
   }

  const getType = (type: string, attributes: any[]) => {

    let typevalue = "";
    if (typeof attributes === 'object' && attributes.length > 0) {
      attributes.map((attrivalue: any, key: number) => {

        if (attrivalue.type !== undefined && type === attrivalue.type) {

          typevalue = attrivalue.value;
        }

      })
    }
    return typevalue;
  };

  const getColumns = async () => {

    
    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + "collections/" + collectionID,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {


        //res.data.data.map((value: any[], key: number) =>{
        let value = res.data.data.attributes;

        setAttributes(value);

        for (const attributevalue in value) {

          let additionalcolumn: any = {
            title: attributevalue,
            dataIndex: attributevalue.replace(" ",""),
            key: attributevalue.replace(" ",""),
            width: 200, 
            sorter: (a: any, b: any) => (typeof a[attributevalue.replace(" ","")] === 'string'?a[attributevalue.replace(" ","")].localeCompare(b[attributevalue.replace(" ","")]):a[attributevalue.replace(" ","")]-b[attributevalue.replace(" ","")]),
 
          };  
          columns.push(additionalcolumn);
    
        }  

        localStorage.setItem("columnlist", JSON.stringify(value));
        getRates();

        let ownercolumn: any = {
          title: 'Owner',
          dataIndex: 'owner',
          key: 'owner',
          width: 200,

          render: (text: string) => <>{text !== "" && <span onClick={() => {

            if ('clipboard' in navigator) {
              navigator.clipboard.writeText(text);
              message.info('Copied to clipboard');
            } else {
              document.execCommand('copy', true, text);
              message.info('Copied to clipboard');
            }


          }}><CopyOutlined />  {text.slice(0, 6) + "..." + text.slice(-6)}</span>}</>,
        };

        columns.push(ownercolumn);



        columns.push({
          title: 'Listed On',
          dataIndex: 'listed',
          key: 'listed',
          width: 220,
          sorter: (a: any, b: any) => a.listed.localeCompare(b.listed),
        });
        columns.push({
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          width: 100,         
          sorter: (a: any, b: any) => a.price - b.price,
        });
        let usdcolumn: any = {
          title: 'USD',
          dataIndex: 'usd',
          key: 'usd',
          width: 150,
          sorter: (a: any, b: any) => a.usd - b.usd,
          render: (text: string) => (parseInt(text) > 0 ? priceFormat(text) : "-"),
        };
        columns.push(usdcolumn);

        setcolumn(columns);

      }
    ).catch((e: Error) => {
      console.log(e);
    });

  };
  const getAttributes = async (attributes: any, value: any, keyval: any) => {


    for (const attributevalue in attributes) {

     
      if (typeof value.attributes === 'object' && value.attributes.length > 0) {
        let attr = { [attributevalue.replace(" ", "")]: getType(attributevalue, value.attributes) };
        keyval = Object.assign({}, keyval, attr);

      } 
    }

    return keyval;
  }

  const getRates = async () => {


    await axios.get(process.env.REACT_APP_API_COIN+"").then(
      (res: AxiosResponse) => {

        setloading(false);
        setRates(res.data.market_data.current_price.usd);
        getData(res.data.market_data.current_price.usd, "");

      }
    ).catch((e: Error) => {
      console.log(e);
    });

  };



  const getData = async (rates: any, link: any) => {


    setloading(true);

    let URL = process.env.REACT_APP_API_BASE_URL + "collections/" + collectionID + "/all-tokens";
    if (link !== '') {
      URL = link;
    }

    
    // URL = URL + "?page[size]=" + localStorage.getItem("perpage") + "&page[number]=" + localStorage.getItem("Offset");

    await axios({ 
      method: 'get',
      url: URL,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        setloading(false);
        setMeta(res.data.meta);


        let tokenlist: any = [];
        res.data.data.map(async (value: any, key: number) => {

          let keyval = {
            key: key + 1,
            name: value.name,
            image: value.image_uri,
            rank: value.rank,
            owner: (value.owned_by !== null ? value.owned_by : ""),
            listed: (value.market_place !== null ? value.market_place : " "),
            price: value.price,
            usd: rates * value.price 
          }

          let jsonstring: any = localStorage.getItem("columnlist");
          columnlist = JSON.parse(jsonstring);
          tokenlist.push(await getAttributes(columnlist, value, keyval));


        })
        setTokens(tokenlist);
        setTokenstemp(tokenlist);


      }

    ).catch((e: Error) => {
      console.log(e);
    });

    
    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + "collections/" + collectionID,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {

        setloading(false);
        setCollection(res.data.data.name);
        setCollectionimg(res.data.data.image);

      }
    ).catch((e: Error) => {
      console.log(e);
    });


  };


  let setattribute:any[] = [];  
  let setattributekey:any = [];  
  function*  checkattrvalue(filteredData: any, attributekey: any){
  
   
    yield filteredData.filter((item: any) => { 
      let Attributesfilter = JSON.parse(""+localStorage.getItem("Attributesfilter"));
      let checkstatus = false; 
      if(Attributesfilter.length > 0){  
        // Attributesfilter.map((value: any, key: number) =>   {
         Attributesfilter.forEach((value: any)=>{
           if(item[attributekey] === value[attributekey]){
             checkstatus = true; 
           }
           /* if(Object.keys(item).find(key => item[key] === value[key])){
             checkstatus = true; 
           }  */
         
         });  
         }else{ 
           checkstatus = true;
         }
         return checkstatus;  
        
  }); 
  
  }
  
   
    
  const clearFilter = () => {
    var textinputs = document.querySelectorAll("input.morefilter");
    [].filter.call( textinputs, function( el: any) {
      
      if(el.checked===true){
        el.checked = false;
      } 
    });  
    setfiltervalue(false);
  }
  
  const setfiltervalue = async(closepopup: boolean)=>{
  
    setFilterenable(1); 
    var textinputs = document.querySelectorAll("input.morefilter");
    [].filter.call( textinputs, function( el: any) {
      
      if(el.checked===true){
        let attr = {[el.name]: el.value};
        setattribute.push(attr);
        setattributekey.push(el.name); 
      } 
    });   
   setTokens(tokenstemp);   
   localStorage.setItem("Attributesfilter", JSON.stringify(setattribute))
   if(closepopup){
    setVisible(!visible);
   }
   var filteredData = tokenstemp;
     
   setattributekey.forEach(async(attributeval: any)=>{    
    
    filteredData = checkattrvalue(filteredData, attributeval).next().value
    setTokens(filteredData);   
  
   /* Promise.resolve(checkattrvalue(tokens, attributeval).next().value).then(async function(filteredData){
      console.log(filteredData);   
       setTokens(filteredData);  
       await delay(5000);  
       return    filteredData;
       
  }).then(function(filteredData){
    console.log(filteredData);   
      
  }) */
  
      
       
      
  })
     
  
  
  }  
    

  const closeImg = (
    <Image src='../../images/close.svg' preview={false} className="clost-btn" alt='close' />
  );

  let loadAttributes = (Attributes: any) => {

    const items = []
    let count = 1;
    for (const attributevalue in Attributes) {

      items.push(<Panel header={attributevalue} key={count}>
        <Row className="popup-filter">

          {Attributes[attributevalue].map((value: any, key: number) => (<Col xs={24} sm={24} md={12} lg={12}>
            <label  >
              <input type="checkbox" className="morefilter" name={attributevalue.replace(" ", "")} value={value.name} />
              <span>{value.name}</span>
              {closeImg}
            </label>
          </Col>))}

        </Row>
      </Panel>);
      count++;
    }
    return items;
  }

  return (<Layout className="layout">
    <HeaderC toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />
    <Content style={{ padding: '50px 50px' }}>
      <div className="token-row">
        <Innermenu slug={"" + collectionID} menuactive={"alltokens"} confilter={false} />
        <div className="token-filter">
          <div className="filter-nav" onClick={() => setVisible(true)}>More Filters <Image preview={false} src="../../images/config.svg" alt='Filter' /></div>
        </div>
      </div>
      <form id="morefilter">
        <Modal className="token-popup" title="More Filters" centered={true} visible={visible} closeIcon={<Image preview={false} onClick={() => setVisible(!visible)} src="../../images/close.svg" alt='Close' />} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} maskClosable={false}>
          <Collapse accordion expandIcon={() => <Image preview={false} src="../../images/arrow.svg" alt='arrow' />}>
            {loadAttributes(Attributes)}
          </Collapse>
          <Row className="foot-body">
            <Col xs={24} sm={24} md={12} lg={24}>
              <Button className="clear-btn" onClick={() => clearFilter()}>Clear Filters</Button>
              <Button onClick={() => setfiltervalue(true)}>Apply Filters</Button>
            </Col>
          </Row>
        </Modal>
      </form>

      <div className={`table-tokens listed-tokens-tbl ${loading === true ? 'loader' : ''}`}>
        {loading === false && tokens.length > 0 && <><h4 className="table-rcd"><span>{tokens.length <= 1 ? `Total Record:` : `Total Records:`}</span>{tokens.length}</h4><Table dataSource={tokens} columns={column} pagination={{ position: ["topCenter", "bottomCenter"], defaultPageSize: 25,hideOnSinglePage: (tokens.length>=25?false:true), pageSizeOptions: ['25', '50', '75', '100'] }} scroll={scrollBar} /></>}
        {loading === true && <Spin size="large" />}
        {filterenable === 1 && tokens.length < 1 && <div className="solo-container">
          <p className="no-rec">No results found.
            <span>We couldn't find anything matching your filters search. Try again with a different term.</span>
          </p>
        </div>}
      </div> <br />


    </Content>
    <Footerc />
    {/* <BackTop>
      <div style={style}>UP</div>
    </BackTop> */}
  </Layout>);

}

export default Alltokens;     