import useLocalStorage from 'react-use-localstorage';
import React, { useState, useEffect, FC} from "react";
import axios, {AxiosResponse} from 'axios';
import { useParams,Link } from 'react-router-dom';
// import { FixedType  } from 'rc-table/lib/interface'
// import ReactPaginate from 'react-paginate';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Layout, Image, Collapse, message, Table, Spin, Modal, Button, Row, Col, Tooltip} from 'antd';
import {  CopyOutlined } from '@ant-design/icons';
import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
import './tokenholders.less';
import Innermenu from "../layout/innermenu"; 
//const { Option } = Select;



const {  Content } = Layout;
//const style = { background: '#0092ff', padding: '8px 0' };
const { Panel } = Collapse;

  const Tokenholders:FC = () => {
 
  let [holders, setHolders] = useState([]); 
  let [holderstemp, setHolderstemp] = useState([]); 
  
  const [loading, setloading] = useState(true);
  
  const [Attributes, setAttributes] = useState<any>([]);
const [visible, setVisible] = useState(false);
//const [Meta, setMeta] = useState<any>();
const [filterenable, setFilterenable] = useState(0);
 
const { collectionID } = useParams();
const [winwidth, setwinwidth] = React.useState(window.innerWidth);

const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    }

    var rootElement = document.getElementsByTagName('html')[0];
    // let darkvar = 'dark';
    // let lightvar = 'light';
    rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
    // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
    // console.log(`Dark Mode: ${props.darkmodeTog}`);
    // {props.darkmodeTog}
    // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):false);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  const updateWidthAndHeight = () => {
    setwinwidth(window.innerWidth);
  };

  let scrollBar = {}
  if(winwidth > 520) {
    scrollBar = {
      x: '100%', y: 750
    }
  } else {
    scrollBar = {
      x: '100%'
    }
  }

  useEffect(() => {
    getData("");
  }, []);
  
 
  

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a: any, b: any) => a.rank - b.rank,
      width: 90,
    },  
    {
      title: 'Owners',
      dataIndex: 'owners',
      key: 'owners',
      className: 'cpy',
      sorter: (a: any, b: any) => a.owner - b.owner,
      render: (text: string) => <><span onClick={() => {
        if ('clipboard' in navigator) {
          navigator.clipboard.writeText(text);
          message.info('Copied to clipboard');
        } else {
           document.execCommand('copy', true, text);
           message.info('Copied to clipboard');
        }
     }}><CopyOutlined /></span><Link to={`/subtoken/${collectionID}/${text}`}>
        {text.slice(0,6)+"..."+text.slice(-6)}
        </Link></>,
     width: 200,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      sorter: (a: any, b: any) => a.label.localeCompare(b.label),
      width: 190,
    },
    { 
      title: '# of Tokens',
      dataIndex: 'tokens',
      key: 'tokens', 
      sorter: (a: any, b: any) => a.tokens -b.tokens,
      width: 150,
    },
    {
      title: 'Top Ranked Token',
      dataIndex: 'toprank',
      key: 'toprank', 
      sorter: (a: any, b: any) => a.toprank-b.toprank,
      width: 200,
    },
    {
      title: 'Images',
      dataIndex: 'images',
      key: 'images',
      render: (text: any[]) => text.map((value: any, key: number) =>(
        
        <Tooltip key={key} placement="bottomLeft" arrowPointAtCenter={true} autoAdjustOverflow={true} title={
          <>
            <Image preview={false} className="zoom"  src={value}/>
          </>
        } overlayClassName="zoom-prv prv-left">
        <Image
          width={36}
          src={`${value}`}
          preview={false}
          placeholder={<Image  
            preview={false} 
            src={theme === 'dark' ? "../images/dark-no-image.jpg" : "../images/no-image.jpg"}    
            width={36}  
          />}  
        />  
        </Tooltip> 
      ))
    }    
  
  ];

 
  


 
  const getData = async (link: any) => {

    setloading(true);

    let URL = process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/token-holders";
    if(link!==''){
      URL = link; 
    } 
    URL = URL+"?page[size]="+localStorage.getItem("perpage")+"&page[number]="+localStorage.getItem("Offset");

    await axios({ 
      method: 'get',
      url: URL,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        
        setloading(false);
        //setMeta(res.data.meta);
         

        setHolders(res.data.data.map((value: any, key: number) =>({
            key:key+1,
            rank: value.rank,
            tokens:value.count,
            owners:value.owned_by, 
            label:value.label, 
            toprank:  value.highest_rank,
            images:value.images            
        })));
        setHolderstemp(res.data.data.map((value: any, key: number) =>({
            key:key+1,
            rank: value.rank, 
            tokens:value.count,
            owners:value.owned_by, 
            label:value.label, 
            toprank:  value.highest_rank,
            images:value.images            
        })));
      
      
      }
    ).catch((e: Error) => { 
      console.log(e);
    });    

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        
        setloading(false);
        let value = res.data.data.attributes;
        setAttributes(value);  
     
      }
    ).catch((e: Error) => {
      console.log(e);
    });
 

  };

    

  let setattribute:any[] = [];  
  let setattributekey:any = [];  
  const checkattrvalue = function(item: any, searchkey: any, Attributesfilter: any){
  
    let checkstatus = false;
   
    if(Attributesfilter.length > 0){
    
      Attributesfilter.filter(async(value:any)=>{ 
      
      if(item[searchkey] === value[searchkey]){
    
        checkstatus = true; 
      }   
    
    });  
    }else{
      checkstatus = false;
    } 
    
    return checkstatus;   
  
  }
   
  const clearFilter = () => {
    var textinputs = document.querySelectorAll("input.morefilter");
    [].filter.call( textinputs, function( el: any) {
      
      if(el.checked===true){
        el.checked = false;
      } 
    });  
    setfiltervalue(false);
  }
  
  
  const setfiltervalue = async(closepopup: boolean)=>{
  
    setFilterenable(1); 
    var textinputs = document.querySelectorAll("input.morefilter");
    var filled = [].filter.call( textinputs, function( el: any) {
      
      if(el.checked===true){
        let attr = {[el.name]: el.value};
        setattribute.push(attr);
        setattributekey.push(el.name); 
      } 
    });   
    
  
   if(closepopup){
    setVisible(!visible);
   }
 
  
  let usetattributekey = setattributekey.filter((c: any, index: any) => {
    return setattributekey.indexOf(c) === index; 
  });
  
  setHolders(holderstemp); 
  
  usetattributekey.filter(async(itemvalue:any)=>{ 
  
    
    let filteredData = holders.filter((item: any) => { return checkattrvalue(item, itemvalue, setattribute);
      
    }); 
        
    setHolders(filteredData); 
    
   }); 
   
  
  } 
 
  
const closeImg = (
  <Image src='../../images/close.svg' preview={false} className="clost-btn" alt='close' />
 );

let loadAttributes = (Attributes: any) => { 

  const items = []
  let count = 1;
  for (const attributevalue in Attributes) {
    
    items.push(<Panel header={attributevalue} key={count}>
        <Row className="popup-filter">
          
        {Attributes[attributevalue].map((value: any, key: number) => ( <Col xs={24} sm={24} md={12} lg={12}>
            <label  > 
              <input type="checkbox" className="morefilter" name={attributevalue.replace(" ","")} value={value.name} />
              <span>{value.name}</span> 
              {closeImg}
            </label>  
          </Col>))}  
           
        </Row>
      </Panel>); 
      count++;
  }  
  return items;
 }



  return( <Layout className="layout">
    <HeaderC toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />
  <Content style={{ padding: '50px 50px' }}>
    

        <Innermenu slug={""+collectionID} menuactive={"tokenholders"}  confilter={false} />	
        {/* <div className="token-filter"> 
            <div className="filter-nav" onClick={() => setVisible(true)}>More Filters <Image preview={false} src="../../images/config.svg" alt='Filter' /></div>
</div> */}
  
      <form id="morefilter">
            <Modal className="token-popup" title="More Filters"  centered={true} visible={visible} closeIcon={<Image preview={false} onClick={()=>setVisible(!visible)} src="../../images/close.svg" alt='Close' />} cancelButtonProps={{style:  {display: 'none'}}} okButtonProps={{style:  {display: 'none'}}} maskClosable={false}>
              <Collapse accordion expandIcon={() => <Image preview={false} src="../../images/arrow.svg" alt='arrow' />}>
                
             
              {loadAttributes(Attributes)}
        
              </Collapse> 
              <Row className="foot-body">
              <Col xs={24} sm={24} md={12} lg={24}>
                <Button className="clear-btn" onClick={()=>clearFilter()}>Clear Filters</Button>
                  <Button onClick={()=>setfiltervalue(true)}>Apply Filters</Button>
                </Col> 
              </Row> 
            </Modal>
            </form>


       
  
<div className={`table-tokens listed-tokens-tbl ${loading === true ? 'loader' : ''}`}>
 
{loading === false && holders.length > 0 && <><h4 className="table-rcd"><span>{holders.length <= 1 ? `Total Record:` : `Total Records:`}</span>{holders.length}</h4>
<Table columns={columns} dataSource={holders}
      pagination={ { position: ["topCenter", "bottomCenter"], defaultPageSize: 25, hideOnSinglePage: (holders.length>=25?false:true),  pageSizeOptions: ['25', '50', '75', '100'] }}
      scroll={scrollBar}
    /></>}
  {loading===true &&  <Spin size="large" /> }

  {filterenable===1 && holders.length < 1 &&  <div className="solo-container no-hide">
      <p className="no-rec">No results found.
      <span>We couldn't find anything matching your filters search. Try again with a different term.</span></p>
    </div>}


</div> <br /> 
   
       
  </Content> 
  <Footerc />
    {/* <BackTop>
      <div style={style}>UP</div>
    </BackTop> */} 
</Layout>);

  }

export default Tokenholders;        