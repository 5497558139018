import useLocalStorage from 'react-use-localstorage';
import React, { useState, useEffect, FC } from "react";
import axios, {AxiosResponse} from 'axios';
  
//import debounce from 'lodash.debounce';

import { Layout, Image, Row, Col, Table, Spin, Input, Tooltip} from 'antd';
import { SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
import Market from "./market";
import Volume from "./volume";
import Circulating from "./circulating"; 
import Sol from "./sol";

const { Content } = Layout;
const style = { background: '#0092ff', padding: '8px 0' };

 
 
   
  const Home:FC = () => {
 
  let [collection, setCollection] = useState<any>([]);
  let [collectiontemp, setCollectiontemp] = useState([]);
  const [loading, setloading] = useState(true);
  const [rates, setRates] = useState<any>();
  const [searchText, setSearchText] = useState("");
  const [Marketdata, setMarketdata] = useState("");
  const [Circulatingsupply, setCirculatingsupply] = useState("");
  const [Volumes, setVolumes] =useState("");
  const [Currentprice, setCurrentprice] = useState("");
  const [Marketdatachg, setMarketdatachg] = useState(Number);
  
  const [Currentpricechg, setCurrentpricechg] = useState(Number);
  const [solanapricedata, setsolanapricedata] = useState<any>([]);
  const [solanamarketdata, setsolanamarketdata] = useState<any>([]);
  const [solanavolumedata, setsolanavolumedata] = useState<any>([]);
  const [solanacirdata, setsolanacirdata] = useState<any>([]);
  const [filterenable, setFilterenable] = useState(0);


  /* Dark Mode -- START */

  // const [darkmode, setDarkmode] = useState<any>(false);
  // useEffect(() => {
  //   if (document.getElementsByTagName('html')[0].classList.contains('dark')) {
  //     setDarkmode(true);
  //   }
  //   if (document.getElementsByTagName('html')[0].classList.contains('light')) {
  //     setDarkmode(false);
  //   }
  // }, [darkmode]);

  /* Dark Mode -- END */

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    }

    var rootElement = document.getElementsByTagName('html')[0];
    // let darkvar = 'dark';
    // let lightvar = 'light';
    rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
    // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
    // console.log(`Dark Mode: ${props.darkmodeTog}`);
    // {props.darkmodeTog}
    // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):false);

  useEffect(() => {
    getRates();
    getRatesnew();
   
  }, []);
  
  const priceFormat = (amount: any) => {
    var price = Math.round(amount).toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
     minimumFractionDigits:0
   });
   return price;
   }

   const priceFormatWithoutCurrency = (amount: any) => {
    var price = Math.round(amount).toLocaleString("en-US", {
     minimumFractionDigits:0
   });
   return price;
   }

  const numberFormat = (amount: any) => {

    let showval = "";
    if(amount > 999 && amount < 1000000){
      showval =  (amount/1000).toFixed(2) + ' K'; // convert to K for number from > 1000 < 1 million 
    }else if(amount > 1000000 && amount < 1000000000){
      showval = (amount/1000000).toFixed(2) + ' M'; // convert to M for number from > 1 million 
    }else if(amount > 1000000000 && amount < 1000000000000){
      showval = (amount/1000000000).toFixed(2) + ' B'; // convert to M for number from > 1 million 
    }else if(amount < 1000){
      showval = amount;
    }
     return  showval;

   }  

  const priceFormatnew = (amount: any) => {

    if(amount > 999 && amount < 1000000){
      return "$"+(amount/1000).toFixed(2) + ' K'; // convert to K for number from > 1000 < 1 million 
    }else if(amount > 1000000 && amount < 1000000000){
      return "$"+(amount/1000000).toFixed(2) + ' M'; // convert to M for number from > 1 million 
    }else if(amount > 1000000000 && amount < 1000000000000){
      return "$"+(amount/1000000000).toFixed(2) + ' B'; // convert to M for number from > 1 million 
    }else if(amount < 1000){
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });; // if value < 1000, nothing to do
    }


   }
   
const columns = [
  {
    title: 'Collections',
    dataIndex: 'collections',
    key: 'collections',
    width:300,
    sorter: (a: any, b: any) => a.collections[0].localeCompare(b.collections[0]),
    render: (text: string) => <><a href="#" onClick={() => {
      window.location.href = "/overview/"+text[2]
   }}><Tooltip placement="rightTop" arrowPointAtCenter={true} autoAdjustOverflow={true} title={
    <>
      <Image preview={false}  src={text[1]}/>
    </> 
  } overlayClassName="zoom-prv prv-right"><Image preview={false}  placeholder={<Image  
  preview={false} 
  src={theme === 'dark' ? "../images/dark-no-image.jpg" : "../images/no-image.jpg"}   
  width={36} 
/>}   src={text[1]}/></Tooltip></a>&nbsp;<a href="#" onClick={() => {
    window.location.href = "/overview/"+text[2]
 }}>{text[0]}</a></>
  },  
  
 
 { 
    title: 'Tokens',
    dataIndex: 'Tokens',
    key: 'Tokens',
    sorter: (a: any, b: any) => a.Tokens-b.Tokens,
     
  },
  {
    title: 'Owners',
    dataIndex: 'owners',
    key: 'owners',
    sorter: (a: any, b: any) => a.owners-b.owners,
  },
  {
    title: 'Listed %',
    dataIndex: 'listed',
    key: 'listed',   
    sorter: (a: any, b: any) => a.listed-b.listed,
    render: (text: number) =>   text > 0?priceFormatWithoutCurrency(text)+"%":"-",
  },
  {
    title: 'Floor',
    dataIndex: 'Floor',
    key: 'Floor',
    sorter: (a: any, b: any) => a.Floor-b.Floor,
    render: (text: number) =>   text!==null?text+" SOL":"-",
  
  }, 
  {  
    title: 'USD',
    dataIndex: 'usd',
    key: 'usd',
    sorter: (a: any, b: any) => a.usd-b.usd,
    render: (text: number) => text>0?priceFormat(text):"-",
  },
  { 
    title: 'Median',
    dataIndex: 'median', 
    key: 'median',
    sorter: (a: any, b: any) => a.median-b.median,
    render: (text: string) =>   text!==null?text+" SOL":"-",
  }, 
  {
    title: 'USD',
    dataIndex: 'usdn',
    key: 'usdn',
    sorter: (a: any, b: any) => a.usdn-b.usdn,
    render: (text: number) =>  text>0?priceFormat(text):"-",
  },
  {
    title: 'Total Floor Value',
    dataIndex: 'totalfloor',
    key: 'totalfloor',
    sorter: (a: any, b: any) => a.totalfloor-b.totalfloor,
    render: (text: number) => text>0?priceFormat(text):"-",
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (text: string) => <button onClick={() => {
       window.location.href = "/overview/"+text
    }}>Explore Data</button>,
  },

]; 
//const excludeColumns = ["Tokens","owners","Floor","usdn","usd","median","listed","totalfloor","action","collections"]; 




const getRates = async () => {

  let solanaprice: any[] = [];
  let solanamarket: any[] = [];
  let solanavolume: any[] = [];
  let solanacir: any[] = [];
     
   
    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"solana?filter[period]=month",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
    (res: AxiosResponse) => {
        
      
     res.data.data.map((value: any, key: number) => {   
        solanavolume.push(value.volume) 
        solanacir.push(value.circulating_supply)
        solanamarket.push(value.market_cap)
        solanaprice.push(value.price)
      }); 
       
      setloading(false); 
      setsolanapricedata(solanaprice.reverse());
      setsolanamarketdata(solanamarket.reverse());
      setsolanavolumedata(solanavolume.reverse());
      setsolanacirdata(solanacir.reverse());
       

    } 
    ).catch((e: Error) => {
    console.log(e);
    }); 
    
 



    await axios.get(process.env.REACT_APP_API_COIN+"").then(
    (res: AxiosResponse) => {
      
      
      setRates(res.data.market_data.current_price.usd);
     // getData(res.data.data.current_price);
    
    }
  ).catch((e: Error) => {
    console.log(e);
  }); 

};


const getRatesnew = async () => {
  
  await axios.get("https://api.coingecko.com/api/v3/coins/solana").then(
    (res: AxiosResponse) => {
      setMarketdata(res.data.market_data.market_cap.usd);
      setCirculatingsupply(res.data.market_data.circulating_supply);
      setVolumes(res.data.market_data.total_volume.usd);
      setCurrentprice(res.data.market_data.current_price.usd);
      setCurrentpricechg(res.data.market_data.price_change_percentage_24h);
      setMarketdatachg(res.data.market_data.market_cap_change_percentage_24h)
      getData(res.data.market_data.current_price.usd);
    }
  ).catch((e: Error) => {
    console.log(e);
  }); 
};



// handle change event of search input
const handleChange = async(value: any) => {
  setSearchText(value);
  filterData(value);

};

  const getData = async (rates: any) => {

     
    
    /* axios.get(process.env.REACT_APP_API_BASE_URL+"collections/summary")*/

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/summary",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(async(res: AxiosResponse) => {

      await setData(res.data.data,rates);
      
      setTimeout(()=>{
        setloading(false);
      },2500);
      
      }
    ).catch((e: Error) => {
      console.log(e);
    });
  };


  const setData = async(data:any,rates:any) => {
    await setCollection(data.map((value: any, key: number) =>({
      key:key,
      collections: [value.name, value.image, value.slug],
      collection_name: value.name,
      Tokens:parseInt(value.supply_total),
      owners:value.owners_total,
      listed:(value.listed_percentage).replace("%",""),  
      listed_total:value.listed_total,  
      Floor:value.floor_sol,
      usd:value.floor_sol*rates,
      median:value.median_sol, 
      usdn:value.median_sol*rates, 
      totalfloor:rates*value.floor_sol_total, 
      action:value.slug,     
}))); 
await setCollectiontemp(data.map((value: any, key: number) =>({
  key:key,
  collections: [value.name, value.image, value.slug],
  collection_name: value.name,
  Tokens:parseInt(value.supply_total),
  owners:value.owners_total,
  listed:(value.listed_percentage).replace("%",""),  
  listed_total:value.listed_total,  
  Floor:value.floor_sol,
  usd:  value.floor_sol*rates,
  median:value.median_sol, 
  usdn:  value.median_sol*rates, 
  totalfloor: rates*value.floor_sol_total, 
  action: value.slug,     
})));
  }

  const filterData = (value: any) => {

    const lowercasedValue = value.toLowerCase().trim();
    setFilterenable(1);  
    let result:any = [];
    if (lowercasedValue.length>0){
      collectiontemp.map((item:any)=>{
        if(item.collection_name.toString().toLowerCase().includes(lowercasedValue))
        {
          result.push(item);
        }
      })
      setCollection(result)
    }else {
      setCollection(collectiontemp);
  }
  };


const styles = {
  green: {
    color: "#2fc212",
  },
  red: {
    color: "#ff0000",
  }
};



  return( <Layout className="layout">
    <HeaderC toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />
   
    <div className="solo-head-chart">
      <Row>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="solo-lay-chart">
            <div className="solo-chart-head">
              <p>
                <span className="chart-title">Market Cap</span> 
                <Tooltip title={
                  <>
                    <p>The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market.</p>
                    <p>Market Cap = Current Price x Circulating Supply.</p>
                  </>
                } color="#252525">
                  <InfoCircleFilled />
                </Tooltip>
              </p>
              <p className="chart-dec"><span style={Marketdatachg >= 0?styles.green:styles.red}>{Marketdatachg >= 0?"+"+Marketdatachg.toFixed(1):Marketdatachg.toFixed(1)}%</span></p>
              <div className="solo-title">
                <h5>{priceFormatnew(Marketdata)}</h5>
              </div>
            </div>
            <div className="solo-chart-innr">
                <Market solanamarketdata={solanamarketdata} fillVal={theme === 'dark' ? 'l(90) 0:#1890ff 0.1:#0496ff 1:#7ec2f300' : 'l(90) 0:#1890ff 0.1:#7ec2f3 1:#ffffff'} loadingval={loading} />
                <div className="chart-yaxis">
                  <span className="left"> </span>
                  <span className="right">Last 1 month</span>
                </div>
              </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="solo-lay-chart">
            <div className="solo-chart-head">
              <p> 
                <span className="chart-title">Volume (24H)</span> 
                <Tooltip title={
                  <>
                    <p>A measure of how much of a cryptocurrency was traded in the last 24 hours.</p>
                  </>
                } color="#252525">
                  <InfoCircleFilled />
                </Tooltip>
              </p>
              {/* <p className="chart-inc">+24.85%</p> */}

              <div className="solo-title">
                <h5>{priceFormatnew(Volumes)}</h5>
              </div>
            </div>
            <div className="solo-chart-innr">
                <Volume fillVal={theme === 'dark' ? 'l(90) 0:#fad5d1 0.1:#ff08ea 1:#7ec2f300' : 'l(90) 0:#fad5d1 0.1:#f37ee9 1:#ffffff'} solanavolumedata={solanavolumedata} loadingval={loading} />
                <div className="chart-yaxis">
                <span className="left"> </span>
                  <span className="right">Last 1 month</span>
                </div>
              </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="solo-lay-chart">
            <div className="solo-chart-head">
              <p>
                <span className="chart-title">Circulating Supply</span>
                <Tooltip title={
                  <>
                   <p>The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.</p>
                  </>
                } color="#252525">
                  <InfoCircleFilled />
                </Tooltip>
              </p>
             {/*  <p className="chart-dec">-3.14%</p> */}
              <div className="solo-title">
                <h5>{numberFormat(Circulatingsupply)}</h5>
              </div> 
            </div> 
            <div className="solo-chart-innr">
                <Circulating solanacirdata={solanacirdata} fillVal={theme === 'dark' ? 'l(90) 0:#1890ff 0.1:#0496ff 1:#7ec2f300' : 'l(90) 0:#1890ff 0.1:#7ec2f3 1:#ffffff'} loadingval={loading} />
                <div className="chart-yaxis">
                <span className="left"> </span>
                  <span className="right">Last 1 month</span>
                </div>
              </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="solo-lay-chart">
            <div className="solo-chart-head">
              <p>
                <span className="chart-title">SOL/USD</span>
                <Tooltip title={
                  <>
                    {/* <h6><CheckCircleFilled /> Verified Data</h6>
                    <p>The CoinMarketCap team has verified the project's SOL/USD to be as follows:</p> */}
                    <p className="solo-gray">
                      <span>SOL/USD:</span>
                      <span className="price">${Currentprice}</span>
                    </p>
                  </>
                } color="#252525">
                  <InfoCircleFilled />
                </Tooltip>
              </p>  
              <p className="chart-inc"><span style={Currentpricechg >= 0?styles.green:styles.red}>{Currentpricechg >= 0?"+"+Currentpricechg.toFixed(1):Currentpricechg.toFixed(1)}%</span></p>
              <div className="solo-title">
                <h5>${Currentprice}</h5>
              </div>
            </div>
            <div className="solo-chart-innr">
                <Sol fillVal={theme === 'dark' ? 'l(90) 0:#fad5d1 0.1:#ff08ea 1:#7ec2f300' : 'l(90) 0:#fad5d1 0.1:#f37ee9 1:#ffffff'} solanapricedata={solanapricedata}  loadingval={loading} />
                <div className="chart-yaxis">
                <span className="left"> </span>
                  <span className="right">Last 1 month</span>
                </div>
              </div>
          </div>
        </Col>
      </Row>
    </div>

  <Content style={{ padding: '50px 50px' }}>

    <div className="table-lists">
      <div className="table-head">
        <h2>Explore Collections</h2>
        {collection.length > 0 &&
      <p>We have listed <span>{(collection.length?collection.length:'')}</span> collections in total so far.</p>
        }
      </div>

      <div className="table-filter">
        <div className="table-search">
         

          <Input   value={searchText} onChange={e => handleChange(e.target.value)}  placeholder="Search Collections" prefix={<SearchOutlined />} />
        </div>
       
      </div>
 
       <Row id="mob-explore" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={`${filterenable!==1 && collection.length===0? 'loader' : ''}`}>
      {loading===false && collection.map((value: any, key: number) =>{ return (<Col className="gutter-row" xs={24} sm={24} md={24} lg={12} key={key}>      
        <div className="data-con"style={style} onClick={() => {
       window.location.href = "/overview/"+value.action
    }}>
        <a href="#" onClick={() => {
       window.location.href = "/overview/"+value.action
    }}>{value.collections[0]}</a>
      
    <Image preview={false}  placeholder={<Image  
  preview={false} 
  src={theme === 'dark' ? "../images/dark-no-image.jpg" : "../images/no-image.jpg"}   
  width={36} 
/>}  src={value.collections[1]} onClick={() => {
       window.location.href = "/overview/"+value.action
    }} /> 

        <ul> 
		<li>Tokens: <strong>{value.Tokens}</strong></li>
        <li>Holders: <strong>{value.owners}</strong></li> 
        <li>Floor price: <strong>{value.Floor>0?<span>{value.Floor} SOL <em >({priceFormat(rates*value.Floor)})</em></span>:"-"}</strong></li>
        <li>Listed: {value.listed > 0?<strong>{value.listed_total}/{value.Tokens} ({`${value.listed}%`})</strong>:<strong>-</strong>}</li>
        <li>Average price: <strong>{value.median>0?<span>{value.median} SOL <em >({priceFormat(rates*value.median)})</em></span>:"-"}</strong></li>
        <li>Total Floor Value: <strong>{value.totalfloor>0?priceFormat(value.totalfloor):"-"}</strong></li> 
        </ul>  
        </div>       
      </Col>); })}
      {filterenable!==1 && collection.length===0 && <Spin size="large" />}
      {filterenable===1 && loading && <Spin size="large" />}
      {filterenable===1 && loading===false && collection.length < 1 &&<Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
        <div className="data-con">
            <p className="no-rec">No results found.
          <span>We couldn't find anything matching your search. Try again with a different term</span></p> 
        </div>
        { loading &&  <Spin size="large" /> }
      </Col>}
    </Row>
  
      </div>

   
  <div id="explore" className="solo-container">
  {collection.length > 0 && <Table dataSource={collection} pagination={ { position: ["topCenter", "bottomCenter"], defaultPageSize: 50, hideOnSinglePage: (collection.length>=50?false:true), pageSizeOptions: ['50', '75', '100'] }} scroll={{x: 1200}} columns={columns}    />}
  {filterenable===1 && loading===false && collection.length < 1 &&  <p className="no-rec">No results found.
  <span>We couldn't find anything matching your search. Try again with a different term</span></p>
  
  }
  {filterenable!==1 && collection.length===0 && <Spin size="large" />}
  {filterenable===1 && loading && <Spin size="large" />}
  <div className="solo-page"> 
   
    
  </div>
  </div>
  
   {/* <div className="contact">

   <p>Want to list your collections to our community? Contact Us </p>
   </div> */}
     
  </Content>
  <Footerc />
    {/* <BackTop>
      <div style={style}>UP</div>
    </BackTop> */}
</Layout>);

  }

export default Home; 