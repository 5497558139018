import { useState, useEffect, FC } from "react";
import Connect2Phantom from '../Connect2Phantom';
import {Row, Col, Layout, Image, Button, Anchor, Drawer, Select, Spin, Modal, Typography, Alert } from 'antd';
import '../layout/header.less';
const { Content } = Layout;

const { Title, Paragraph, Text } = Typography;
 
  const Login:FC = () => {
    const [showModal, setshowModalw] = useState(true);
    const [showConnectError, setshowConnectError] = useState(false);
    const [showConnectSuccess, setshowConnectSuccess] = useState(false);
    
    const setshowModal = (show: boolean):void => {
      setshowModalw(show)
  }

  
  
  if(localStorage.getItem("logintoken")!=="" && localStorage.getItem("logintoken")!==null && localStorage.getItem("logintoken")!==undefined ){
    window.location.href = '/';
    
  }
   return(   
        
    <div className="ant-modal-root"><div className="ant-modal-mask"></div><div  className="ant-modal-wrap" role="dialog"><div role="document" style={{width: "520p"}} className="ant-modal solo-connect-popup"><div className="ant-modal-content">
      <div className="ant-modal-body">        
      <Image width={260} src="../images/logo-new-wtcaption.png" />
      <Typography>
        <Paragraph> 
          SolObserver.com is your ultimate source for Solana NFT collection data and analytics
        </Paragraph>
        <Paragraph>
          Please connect your wallet to access SolObserver - NFT Data & Analytics Platform.
        </Paragraph>
        <Connect2Phantom showconnect={true} showdisconnect={false} setshowModal={setshowModal} setshowConnectSuccess={setshowConnectSuccess} setshowConnectError={setshowConnectError} />
      </Typography>
      {showConnectError && <Alert
        message="Confirming access, please wait …  " description="Error: Authentication failed."
        type="warning" showIcon 
      />}
      {showConnectSuccess && <Alert 
        message="" description="Confirming access, please wait …"
        type="success" showIcon
      />}
      </div></div></div></div></div>   
    
      );
      
  
  }

export default Login; 