import React, { useState, useEffect, FC, memo } from "react";
import axios, {AxiosResponse} from 'axios';
import { useParams } from 'react-router-dom';
//import { Layout, Tabs, } from 'antd';
import { Column} from '@ant-design/charts';

import {getDate} from '../../helper'

// const { Content } = Layout;
// const style = { background: '#0092ff', padding: '8px 0' };
// const { TabPane } = Tabs;
 
const Tokenowner:FC<{fillVal: any, opaval: any}> = ({fillVal, opaval}) => {

    //const [loading, setloading] = useState(true);
    const { collectionID } = useParams();
    const [listdata, setListdata] = useState([]);
    const [winwidth, setwinwidth] = React.useState(window.innerWidth);

    const [ownersLastUpdate, setownersLastUpdate] = useState("");
        
    useEffect(() => {
        lineData();
        getCollectionData();
    }, []);

    const lineData = async () => { 

         await axios({ 
          method: 'get',
          url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/token-owners",
          headers: { 
            'Authorization': 'Bearer '+localStorage.getItem("logintoken")
          }
        }).then(
        (res: AxiosResponse) => {
            
            //setloading(false);
            

            //let totalChartday1: { tokens: any; owners: any }[] = [];
           
            setListdata(res.data.data.map((value: any, key: number) => ({
              total_tokens: value.total_tokens+" ",
              total_owners: value.total_owners                
            })));
             

        } 
        ).catch((e: Error) => {
        console.log(e);
        }); 
    }


    const getCollectionData = async () => {

      

      await axios({ 
        method: 'get',
        url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("logintoken")
        }
      }).then(
        (res: AxiosResponse) => {
          //setloading(false); 
          setownersLastUpdate((res.data.data.hasOwnProperty("owners_last_updated")?res.data.data.owners_last_updated:""));
        }
      ).catch((e: Error) => {
        console.log(e);
      }); 

    };




    const tipColor = '#5b8ff9';

    const getMax = (arr: any[], prop: any) => {
      const tmp = arr.map(x => x[prop]);
      const max = Math.max(...tmp);
      return arr.filter(x => x[prop] === max);
    }
  
    const updateWidthAndHeight = () => {
      setwinwidth(window.innerWidth);
    };

    let listdatamax = getMax(listdata,"total_owners");

    let label = {}
  if(winwidth > 520){
      label = { 
       
       rotate: -120, 
       layout: [
        {
          type: 'outer',
        },
      ],
       style: {
         fill: fillVal,
         fontSize: 12,
         opacity: opaval, 
       },  
     }; 
   }else{
     label = {formatter: (v: any) => {
       return "";
     },       
    };

   }
     

    const config = {
      data: listdata,
      xField: 'total_tokens',
      yField: 'total_owners',
      label: label, 
      xAxis: {
      
      label: {
          rotate: -120,
          autoRotate: false,
          formatter: (v: string) => {
            return v;
          },
          style: {
            fontWeight: 600,
          }
      }, 
      },
      yAxis: {
        maxLimit: ((listdatamax[0]?.total_owners !== undefined ? listdatamax[0]?.total_owners : 0)+200),
        label: {
          formatter: (v: string) => {
            return parseInt(v);
          },
          style: {
            fontWeight: 600,
          }
        },
        
      },
      tooltip: {
        customContent: (title: any, items: any[]) => { 
          const data = items[0]?.data || {};
          return `<div class="chart-tooltip"><h2># of Tokens: ${title}</h2><div><span style="background: ${tipColor}"></span><p># of owners: ${data.total_owners}</p></div></div>`;
        } 
      },   
              
  }; 
 
  return(
      <>       
        <div className="chart-innr chart-scatter left single-chart">
        <div className="chart-txt">
              <div className="chart-left">
                <h5>Number of nfts per owner</h5>
              </div>  
          </div>
          <div className="chart-srtTxt">
            <p className="chart-horz">Number of owners</p>
            <div className="chart-srt">
                <Column {...config} />
            </div>
            <p className="chart-vert">Number of tokens</p>
          </div>
          <p className="chart-dis"><span>*</span>Does not include tokens listed for sale</p>
          {ownersLastUpdate!=="" && <h5 className="pt-7">Owners Last Updated: <b>{getDate(ownersLastUpdate)}</b></h5>}
        </div>
        
      </>
  )}

export default memo(Tokenowner); 