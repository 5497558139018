import moment from 'moment'

const convertTo12 = (time24)=> {
    let hours = parseInt(time24);
    let hourval = "";
     if(hours < 12){
       hours = (parseInt(time24)!==0?parseInt(time24):12);
       hourval = hours+":00 AM";
     }else if(hours > 12){
       hourval = (hours-12)+":00 PM";
     }else{ 
        hourval = hours+":00 PM";
     } 
     return hourval; 
   } 
  
   const getMax = (arr, prop) => {
    const tmp = arr.map(x => x[prop]);
    const max = Math.max(...tmp);
    return arr.filter(x => x[prop] === max);
  }

  const getMin = (arr, prop) => {
    const tmp = arr.map(x => x[prop]);
    const max = Math.min(...tmp); 
    return arr.filter(x => x[prop] === max);
  }

 
  const getDateformat = (datevalue) =>{
    
    if(datevalue !== null){
    let arrdatetime =  datevalue.split("T");
    
    let arrdate =  arrdatetime[0].split("-");
    return arrdate[1]+"-"+arrdate[2]+"-"+arrdate[0].substring(2)+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
    }else{
      return datevalue;
    }
  }  

  const getDate = (datevalue) =>
  {
    let date; 
    if(datevalue!=="")
    {
      date = moment(datevalue).format("MM-DD-YYYY hh:mm A"); 
    }
    return date;
  }

  const intToString = (value) =>
  {

    return String(value);
  }

   export{convertTo12, getMax, getMin, getDateformat,getDate,intToString} 