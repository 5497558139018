import useLocalStorage from 'react-use-localstorage';
import { useState, useEffect, FC } from "react";
import axios, {AxiosResponse} from 'axios';
import { useParams } from 'react-router-dom';


import { Layout, Image, Row, Tooltip, Table, Modal, Button, Col} from 'antd';
import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
import Innermenu from "../layout/innermenu";
import StackGrid from 'react-stack-grid';
import './rarity.less';

const { Content } = Layout;


 
  const Rarities:FC = () => {

    const [attrifiltervalue, setattrifiltervalue] = useState<any>([]);
  //   const [filterenable, setFilterenable] = useState(0); 
  // const [Collectiontoken, setCollectiontoken] = useState([]);
  const [Rarityvalues, setRarityvalues] = useState<any>([]);
  //const [loading, setloading] = useState<any>([]);
  const { collectionID } = useParams();
  const [visible, setVisible] = useState(false); 
  const [Attributes, setAttributes] = useState<any>([]);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  useEffect(() => { 
    window.addEventListener('resize', setDimension);
    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize]);

  useEffect(() => {
    getData();
  }, []);

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    }

    var rootElement = document.getElementsByTagName('html')[0];
    // let darkvar = 'dark';
    // let lightvar = 'light';
    rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
    // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
    // console.log(`Dark Mode: ${props.darkmodeTog}`);
    // {props.darkmodeTog}
    // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):false);
  

  
  const columns = [
    
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <div className="rari-split"> 
                                  <Tooltip overlayClassName="zoom-prv prv-right" placement="rightTop" arrowPointAtCenter={true} autoAdjustOverflow={true} title={
                                    <>
                                      <Image preview={false} src={text[1]}  /> 
                                    </>
                                  }><Image preview={false} src={text[1]}  /></Tooltip>
                                  <span>{text[0]}</span>  
                                </div>,
      sorter: (a: any, b: any) => a.name[0].localeCompare(b.name[0]),
    },
    {
      title: '#',
      dataIndex: 'count',
      key: 'count',  
      sorter: (a: any, b: any) => a.count-b.count,
    },    
    {
      title: '%',
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a: any, b: any) => a.percentage-b.percentage,
    }              
  
  ];


const getType = (type: string,attributes: any[] ) =>{

  let typevalue = attributes.map((value: any, key: number) =>{

      if(type===value.type){
        return attributes[0].value;
      }

  })
  return typevalue;
};

 
  const getData = async () => {

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        
        //setloading(false);
        //setCollectiontoken(res.data.data.supply_total);
        let value = res.data.data.attributes;
        setAttributes(value);  
      
      } 
    ).catch((e: Error) => {
      console.log(e);
    });

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/rarities",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        
        //setloading(false);
        res.data.data.map((value: any[], key: number) =>{
           
              res.data.data.map((value: any[], key: number) =>{
                let arraylist = []
                for (const attributevalue in value) {
                 
                    let attrcount = [];
                    let cnt = 0;
                    for (const attval in value[attributevalue]) {
                      let attributes = value[attributevalue][attval];
                      let keyvalue = {key:cnt, name:[attval,attributes.image],count: attributes.count, percentage: attributes.percentage};
                      attrcount.push(keyvalue);
                      cnt++;
                    }
                    if(typeof attrcount === 'object' && attrcount.length > 0) {
                     
                      let ratityvalues ={"title":attributevalue, "rarvalues":attrcount};
                      arraylist.push(ratityvalues);
      
                    }
             
                }
                setRarityvalues(arraylist);
                           
              });

           
                     
        });
   
      }
    ).catch((e: Error) => {
      console.log(e);
    });



 

  };


  
const clearFilter = () => {
  var textinputs = document.querySelectorAll("input.morefilter");
  [].filter.call( textinputs, function( el: any) {
    
    if(el.checked===true){
      el.checked = false;
    } 
  });  
  setfiltervalue(false);
}
let setattributekey:any = [];  
const setfiltervalue = async(closepopup: boolean)=>{

  //setFilterenable(1); 
  var textinputs = document.querySelectorAll("input.morefilter");
  var filled = [].filter.call( textinputs, function( el: any) {
    
    if(el.checked===true){
      
      setattributekey.push(el.value); 
    } 
  });   
  
 
 if(closepopup){
  setVisible(!visible);
 }
 setattrifiltervalue(setattributekey);
  
console.log(setattributekey)

} 



  let loadAttributes = (Attributes: any) => { 
 
    const items = []
    let count = 1;
    for (const attributevalue in Attributes) {
      
      items.push(<Col xs={24} sm={24} md={12} lg={12} key={count}>
                    <label  > 
                      <input type="checkbox" className="morefilter" name="attributesfilter" value={attributevalue} />
                      <span>{attributevalue}</span>   
                      {closeImg}
                    </label>  
                    </Col>); 
        count++;
    }  
    return items;
   }


  const closeImg = (
    <Image src='../../images/close.svg' preview={false} className="clost-btn" alt='close' />
   );
   

  return( <Layout className="layout">
    <HeaderC  toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />
  <Content style={{ padding: '50px 50px' }}>

  <div className="token-row">
      <Innermenu slug={""+collectionID} menuactive={"rarities"} confilter={false}   />
      <div className="token-filter"> 
            <div className="filter-nav" onClick={() => setVisible(true)}>More Filters <Image preview={false} src="../../images/config.svg" alt='Filter' /></div>
        </div>
  </div>
  <form id="morefilter"> 
            <Modal className="token-popup" title="More Filters"  centered={true} visible={visible} closeIcon={<Image preview={false} onClick={()=>setVisible(!visible)} src="../../images/close.svg" alt='Close' />} cancelButtonProps={{style:  {display: 'none'}}} okButtonProps={{style:  {display: 'none'}}} maskClosable={false}>

                  <Row className="popup-filter filter-rar">
 
                  {loadAttributes(Attributes)} 
                    
                </Row>
                <Row className="foot-body">
                <Col xs={24} sm={24} md={12} lg={24}>
                <Button className="clear-btn" onClick={()=>clearFilter()}>Clear Filters</Button>
                  <Button onClick={()=>setfiltervalue(true)}>Apply Filters</Button>
                </Col>
              </Row>  
            </Modal> 
            </form> 

 <StackGrid className="rarities-table" columnWidth={screenSize.dynamicWidth <= 768 ? '100%' : screenSize.dynamicWidth <= 1080 ? '48%' : '33.33%'}>

 {Rarityvalues.map((value: any, key: number) =>(attrifiltervalue.includes(value.title) || attrifiltervalue.length < 1) && (<> <div className="rarities-head"><h2>{value.title} </h2> <Table dataSource={value.rarvalues} pagination={false} columns={columns} /></div></>))}

 </StackGrid> 
 
   
   {/* <div className="contact">

   <p>Want to list your collections to our community? Contact Us </p>
   </div> */}
     
  </Content>
  <Footerc />
    {/* <BackTop>
      <div style={style}>UP</div>
    </BackTop> */}
</Layout>);

  }

export default Rarities;    