import useLocalStorage from 'react-use-localstorage';
import React, { useState, useEffect, FC } from "react";
import axios, {AxiosResponse} from 'axios';
import '../dashboard/dashboard.less';
import { useParams } from 'react-router-dom';
import { Layout, Input, Image, Row, Col, Tabs } from 'antd';
import { Line } from '@ant-design/charts';

import HeaderC from "../layout/header";
import Footerc from "../layout/footer";
import Innermenu from "../layout/innermenu";

const { Content } = Layout;
//const style = { background: '#0092ff', padding: '8px 0' };
const { TabPane } = Tabs;
const { Search } = Input;
const { TextArea } = Input;
 
  const FloorInsights:FC = () => {
  //const [state, setstate] = useState([]);
  let [collection, setCollection] = useState("");
  //const [loading, setloading] = useState(true);

  const [listdata, setListdata] = useState([]);
  const { collectionID } = useParams();
  let [collectionimg, setCollectionimg] = useState("");
   
  const [collectionfloorsol, setcollectionfloorsol] = useState("");
  const [collectionmediansol, setcollectionmediansol] = useState("");
  const [rates, setRates] = useState<any>();
//const [visible, setVisible] = useState(false);


useEffect(() => {
  getData();
  lineData();
  getRates();
}, []);

const getData = async () => {

  await axios({ 
    method: 'get',
    url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
    headers: { 
      'Authorization': 'Bearer '+localStorage.getItem("logintoken")
    }
  }).then(
    (res: AxiosResponse) => {
      
      //setloading(false);
      setCollection(res.data.data.name);
      setCollectionimg(res.data.data.image);
      setcollectionfloorsol(res.data.data.floor_sol);
      setcollectionmediansol(res.data.data.median_sol);
      
  
    }
  ).catch((e: Error) => {
    console.log(e);
  }); 
};
  
const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    }

    var rootElement = document.getElementsByTagName('html')[0];
    // let darkvar = 'dark';
    // let lightvar = 'light';
    rootElement.setAttribute( 'class', theme === 'dark' ? 'dark' : 'light' );
    // props.darkmodeTog = theme === 'dark' ? 'true' : 'false';
    // console.log(`Dark Mode: ${props.darkmodeTog}`);
    // {props.darkmodeTog}
    // const currentMode = (sessionStorage.getItem("dark")?(sessionStorage.getItem("dark")==="true"?true:false):false);

const priceFormat = (amount: any) => {
  var price = amount.toLocaleString("en-US", {
   style: "currency",
   currency: "USD"
 });
 return price;
 }


 const getRates = async () => {


  await axios.get(process.env.REACT_APP_API_COIN+"").then(
     (res: AxiosResponse) => {
       
       //setloading(false);
       setRates(res.data.market_data.current_price.usd);
      
     
     }
   ).catch((e: Error) => {
     console.log(e);
   }); 
 
 };


  /* Single Line Start */
  const lineData = async () => {    

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/prices",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
    (res: AxiosResponse) => {
        
        //setloading(false);
        setListdata(res.data.data);
    } 
    ).catch((e: Error) => {
    console.log(e);
    });
}

const getMax = (arr: any[], prop: any) => {
    const tmp = arr.map(x => x[prop]);
    const max = Math.max(...tmp); 
    return arr.filter(x => x[prop] === max);
  }

  const getMin = (arr: any[], prop: any) => {
    const tmp = arr.map(x => x[prop]);
    const max = Math.min(...tmp);
    return arr.filter(x => x[prop] === max);
  }

  let maxvalue = getMax(listdata,"floor_price");
  let minvalue = getMin(listdata,"floor_price");

const config = {
    data: listdata,
    width: 800,
    height: 400,
    autoFit: false,
    xField: 'hour',
    yField: 'floor_price',
    yAxis: {
      minLimit: ((minvalue[0]?.floor_price !== undefined ? minvalue[0]?.floor_price : 0) - 1),
      maxLimit: ((maxvalue[0]?.floor_price !== undefined ? maxvalue[0]?.floor_price : 0) + 1),
    },
    point: {
      size: 7,
      shape: 'circle',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
};

const config2 = {
    data: listdata,
    width: 800,
    height: 400,
    autoFit: false,
    xField: 'date',
    yField: 'floor_price',
    yAxis: {
      minLimit: ((minvalue[0]?.floor_price !== undefined ? minvalue[0]?.floor_price : 0) - 1),
      maxLimit: ((maxvalue[0]?.floor_price !== undefined ? maxvalue[0]?.floor_price : 0) + 1),
    },
    point: {
      size: 7,
      shape: 'circle',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
};
  /* Single Line End */

 


  return( <Layout className="layout">
    <HeaderC toggleMode={
      <>
        <div className="toggle-mode">
          <div className="slide-block slide-out">
          </div>
          <div onClick={switchTheme} className={`ToggleSwitch ${theme === 'dark' ? 'active' : ''}`}>
            <div className={theme === 'dark' ? 'knob active' : 'knob'} />
          </div>
        </div>
      </>
    } />
  <Content style={{ padding: '50px 50px' }}>
  
  
  <div className="table-lists alltokens-tablelist">
        <Image src={collectionimg} width={64} /><h2>{collection}</h2>
      </div> 

       <Innermenu slug={""+collectionID} menuactive={"floor-insights"} confilter={false}   /> 

  <div className="chart-row">
  <Row>
      <Col xs={24} sm={24} md={24} lg={12}>
        <div className="chart-innr">
          <h5 className="chart-title">Your NFT Portfolio</h5>
          <div className="chart-head">
            <Search
              placeholder="Your wallet address"
              allowClear
              enterButton="Import NFTS"
              size="large"
            />
          </div>
          <div className="chart-body">
            <p><strong>or</strong></p>
            <p>Enter your mint addresses below (one per line, max 50)</p>
            <span className="txt-sml">Only SolanaFloor collections are supported</span>
            <TextArea />
          </div>
        </div>
      </Col>
      {/* Single Line Start */}
      <Col xs={24} sm={24} md={24} lg={12}>
      <div className="chart-innr">
            <div className="chart-txt">
              <div className="chart-left">
                <h5>Price</h5>
               
                <h4>Current Floor price: <span>{collectionfloorsol} SOL</span> ({priceFormat(Number(collectionfloorsol)*rates)})</h4>
                <h4>Current Average: <span>{collectionmediansol} SOL</span> ({priceFormat(Number(collectionmediansol)*rates)})</h4>


              </div>
            </div>

       
            <div className="chart-tab">
              <Tabs defaultActiveKey="1">
                <TabPane tab="1D" key="1">
                <div className="chart-srt">
                  <Line {...config} /> 
                  </div>
                </TabPane>
                <TabPane tab="1W" key="2">
                <div className="chart-srt">
                  <Line {...config2} /> 
                  </div>
                </TabPane>
                
              </Tabs>
            </div>
        </div>
      </Col>
      {/* Single Line End */}

     
    </Row>
  </div>

     
  </Content>
  <Footerc />
    {/* <BackTop>
      <div style={style}>UP</div>
    </BackTop> */}
</Layout>);

  }

export default FloorInsights;