import React, { useState, useEffect, FC, memo } from "react";
import axios, {AxiosResponse} from 'axios';
import { useParams } from 'react-router-dom';
import { Tabs,Spin, Col, Select, Modal, Image, Row } from 'antd';
import {  Histogram } from '@ant-design/charts';
import {getDate} from '../../helper'

// const { Content } = Layout;
// const style = { background: '#0092ff', padding: '8px 0' };
const { TabPane } = Tabs;
 
const Pricedistribution:FC<{ loadingval: boolean , colwidthDyn: number, vallabel: any, opaval: any, stroval: any, darkEnb: any}> = ({children,  loadingval, colwidthDyn, vallabel, opaval, stroval, darkEnb}) => {

    const [loading, setloading] = useState(true);
    const { collectionID } = useParams();
    const [winwidth, setwinwidth] = React.useState(window.innerWidth);
    const [binWidth1, setbinWidth1] = useState<any>("");
    const [binWidth2, setbinWidth2] = useState<any>("");
 
    const [pricediscurrent, setpricediscurrent] = useState<any>([]);
    const [pricedisyesterday, setpricedisyesterday] = useState<any>([]);
    const [pricedislastweek, setpricedislastweek] = useState<any>([]);
    const [pricedislastmonth, setpricedislastmonth] = useState<any>([]);

    const [pricediscurrentnear, setpricediscurrentnear] = useState<any>([]);
    const [pricedisyesterdaynear, setpricedisyesterdaynear] = useState<any>([]);
    const [pricedislastweeknear, setpricedislastweeknear] = useState<any>([]);
    const [pricedislastmonthnear, setpricedislastmonthnear] = useState<any>([]);
    const [isModalVisible, setisModalVisible] = useState<any>(false);
    const [isModalVisibletwo, setisModalVisibletwo] = useState<any>(false);

    const [activatekey, setactivatekey] = useState("1");

    const bindWidthValueDesktop = ['1','2','3','5','8','10','15','20','25'];
    const bindWidthValueResponsive = ['5','8','10','15','20','25'];
    let bindWidthValue:any = [];

  const [pricesLastUpdate, setpricesLastUpdate] = useState("");
  const { Option } = Select;

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  useEffect(() => { 
    window.addEventListener('resize', setDimension);

    // console.log(colwidthDyn);
    
    // if(screenSize.dynamicWidth <= 768 && colwidthDyn===12) 
    // {
    //   bindWidthValue = bindWidthValueResponsive;  
    // }
    // else if(colwidthDyn===24)
    // {
    //   bindWidthValue = bindWidthValueDesktop; 
    // }
    // else if(colwidthDyn===12)
    // {
    //   bindWidthValue = bindWidthValueResponsive;  
    // }

    // console.log(bindWidthValue);

    return(() => {
        window.removeEventListener('resize', setDimension);
    })



  }, [screenSize,binWidth1,binWidth2]);

    //const [thisPriceDistribute, setThisPriceDistribute] = useState<any>(pricediscurrent);
 
      
    useEffect(() => {
      getData();
      getCollectionData();

      window.addEventListener("resize", updateWidthAndHeight);
      return () => window.removeEventListener("resize", updateWidthAndHeight);

      
    }, []);

 
    const updateWidthAndHeight = () => {
      setwinwidth(window.innerWidth);
    };

     const getData = async () => {

      

      let pricedistributioncall = axios({ 
        method: 'get',
        url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/price-distributions",
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("logintoken")
        }
      });      


      let pricedistributioncallnear = axios({ 
        method: 'get',
        url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID+"/graphs/price-distributions/near-floor",
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("logintoken")
        }
      });
     
      axios.all([pricedistributioncall, pricedistributioncallnear]).then(axios.spread(async(respricedist, respricedistnear) => { 
        setbinWidth1(respricedist.data.meta.binWidth!=""?respricedist.data.meta.binWidth:0);  
        setbinWidth2(respricedistnear.data.meta.binWidth!=""?respricedistnear.data.meta.binWidth:0);  
        setpricediscurrent(respricedist.data.data.current);  
        setpricedisyesterday(respricedist.data.data.yesterday);  
        setpricedislastmonth(respricedist.data.data.lastMonth);   
        setpricedislastweek(respricedist.data.data.lastWeek);  
  
        setpricediscurrentnear(respricedistnear.data.data.current);  
        setpricedisyesterdaynear(respricedistnear.data.data.yesterday);  
        setpricedislastmonthnear(respricedistnear.data.data.lastMonth);  
        setpricedislastweeknear(respricedistnear.data.data.lastWeek);  
        setloading(false); 

        // use/access the results 
      })).catch(errors => {
        console.log(errors);
      });
 
        
    }



    const getCollectionData = async () => {

      

      await axios({ 
        method: 'get',
        url: process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID,
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("logintoken")
        }
      }).then(
        (res: AxiosResponse) => {
          setloading(false); 
          setpricesLastUpdate((res.data.data.hasOwnProperty("prices_last_updated")?res.data.data.prices_last_updated:""));
        }
      ).catch((e: Error) => {
        console.log(e);
      }); 
    };


    const lineData = async (tabval: string) => {
      setactivatekey(tabval);
    }

    const handleChange = async(e: any) => {
      setloading(true); 
      if(screenSize.dynamicWidth <= 768) {
        setisModalVisible(false);
      } else {
          setisModalVisible(true); 
      }
      setbinWidth1(e);
      //setbinWidth2(e);
      setloading(false); 
    }


    const handleChangefloor = (e: any) => {
      setloading(true); 
      console.log(`value: ${e}`);
      if(screenSize.dynamicWidth <= 768) {
        setisModalVisibletwo(false);
      } else {
        setisModalVisibletwo(true);
      }
      //setbinWidth1(e);
      setbinWidth2(e);
      setloading(false); 
    }


  
let label = {}
  if(winwidth > 520){
      label = { 
       formatter: (v: any) => {        
         return parseInt(v.count);
       },
       rotate: -120, 
       style: {
         fill: vallabel,
         opacity: opaval,
         stroke: stroval,
         fontSize: 12, 
         paddingTop: 10,
       }, 
     }; 
   }else{
     label = {formatter: (v: string) => {
       return "";
     },  };
   }
     
    let columnSty = {};
    let columnSty2 = {};
    // if(darkEnb === 'dark') {

      
    //   if(binWidth1==="0.5" || binWidth1==="1")
    //   {
    //     columnSty = {
    //       stroke: '#3c5d9f',
    //     }
    //   }
    //   else
    //   {
    //     columnSty = {
    //       stroke: '#1f282d',
    //     }
    //   }

    //   if(binWidth2==="0.5" || binWidth2==="1")
    //   {
    //     columnSty2 = {
    //       stroke: '#3c5d9f',
    //     }
    //   }
    //   else
    //   {
    //     columnSty2 = {
    //       stroke: '#1f282d',
    //     }
    //   }
      

    // }else {
      
    // }

    if(binWidth1==="0.5" || binWidth1==="1")
      {
        columnSty = {
          stroke: '#3c5d9f',
        }
    }
    else
      {
        if(darkEnb === 'dark') {
          columnSty = {
            stroke: '#1f282d',
          }
        } else {

        }
    }

    if(binWidth2==="0.5" || binWidth2==="1")
      {
        columnSty2 = {
          stroke: '#3c5d9f',
        }
    }
    else
      {
        if(darkEnb === 'dark') {
          columnSty2 = {
            stroke: '#1f282d',
          }
        } else {

        }
    }
   
    let datacurrent = pricediscurrent;
    const configcurrent = {
      data: datacurrent,
      binField: 'value', 
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty,
      binWidth:binWidth1,
      label: label,
        yAxis: {
          label: {
            formatter: (v: string) => {
              return parseInt(v);
            },
            style: {
              fontWeight: 600,
            }
          },
        },
      xAxis: { 
        label: {
          autoRotate: true,
          rotate: -120,
          formatter: (v: string) => {
            
            return `${v}`;
          },
          style: {
            fontWeight: 600,
          }
        },     
       
      },  
    }; 

   
    const datayesterday = pricedisyesterday;
    const configyesterday = {
      data: datayesterday,
      binField: 'value',
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty,
      binWidth: binWidth1,
      label: label,
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        }
      },
      yAxis: {
        label: {
          style: {
            fontWeight: 600,
          }
        }
      },
    }; 

    
    const datalastweek = pricedislastweek;
    const configlastweek = { 
      data: datalastweek,
      binField: 'value',
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty,
      binWidth: binWidth1, 
      label: label,
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        }
      },
      yAxis: {
          label: {
            style: {
              fontWeight: 600,
            }
          }
      },
    };

     
    const datalastmonth = pricedislastmonth;
    const configlastmonth = {
      data: datalastmonth,
      binField: 'value',
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty,
      binWidth:binWidth1, 
      label: label,    
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        }
      },
      yAxis: {
         label: {
          style: {
            fontWeight: 600,
          }
         }
      }, 
    };

    
    let datacurrentnear = pricediscurrentnear;
    const configcurrentnear = {
      data: datacurrentnear,
      binField: 'value',
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty2,
      binWidth: binWidth2,  
      label: label,
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        }
      },
      yAxis: {
        label: {
          style: {
            fontWeight: 600,
          }
        }
      }, 
    };

    
    const datayesterdaynear = pricedisyesterdaynear;
    const configyesterdaynear = {
      data: datayesterdaynear,
      binField: 'value', 
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty2,
      binWidth: binWidth2,   
      label: label, 
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        },
        yAxis: {
          label: {
            style: {
              fontWeight: 600,
            }
          }
        }
      },
    }; 

    
    const datalastweeknear = pricedislastweeknear;
    const configlastweeknear = { 
      data: datalastweeknear,
      binField: 'value', 
      tooltip: {
        title: (datum: any) => {
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle:columnSty2,
      binWidth: binWidth2,   
      label: label,
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        }
      }, 
      yAxis: {
        label: {
          style: {
            fontWeight: 600,
          }
        }
      }
    };

     
    const datalastmonthnear = pricedislastmonthnear;
    const configlastmonthnear = {
      data: datalastmonthnear,
      binField: 'value',
      tooltip: {
        title: (datum: any) => { 
            const minMax = datum.split(",");
            if (minMax[0] >= 650) {
              return 'Price Range: 650+'
            }
            return 'Price Range: ' + minMax[0] + ' - ' + minMax[1]
        },
        formatter: (datum: any) => {
          return {name: '# of NFTs', value: datum.count}
        },
        
      },
      theme: {
        backgroundColor: '#1f282d00',
      },
      columnStyle: columnSty2,
      binWidth: binWidth2,  
      label: label,     
      xAxis: {
        label: {
          rotate: -120,
          style: {
            fontWeight: 600,
          }
        }
      }, 
      yAxis: {
        label: {
          style: {
            fontWeight: 600,
          }
        }
      }
    }; 

  return(  
      <>
      <Col xs={24} sm={24} md={24} lg={colwidthDyn}>
        <div className="chart-innr left chart-dist">
        <div className="chart-txt">
              <div className="chart-left"> 
                <h5>Price Distribution All</h5>
              </div>
          </div>
         <div className="chart-tab nw-dtab nw-pop">
                {loading === false && <div className="chart-slt">
                                      <span>Interval:</span> {console.log(binWidth1)}
                                     {binWidth1!="" && <Select value={binWidth1} 
                                          style={{ width: 55 }}
                                          onChange={(binWidth1: string) => {handleChange(binWidth1);}}>
                                            {winwidth > 1024 ?
                                              <>
                                            <Option value="0.5">0.5</Option>
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                              </> : <></>}
                                            <Option value="5">5</Option>
                                            <Option value="8">8</Option>
                                            <Option value="10">10</Option>
                                            <Option value="15">15</Option>
                                            <Option value="20">20</Option>
                                            <Option value="25">25</Option>
                                      </Select> }
                                      </div> }
                  <Modal title="PRICE DISTRIBUTION ALL" wrapClassName="chart-popup" width={1180} centered={true}  visible={isModalVisible} closeIcon={<Image preview={false} onClick={()=>setisModalVisible(!isModalVisible)} src="../../images/close.svg" alt='Close' />} cancelButtonProps={{style:  {display: 'none'}}} okButtonProps={{style:  {display: 'none'}}}>
                    <Row className="popup-filter">
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {loading === true && <Spin size="large" />}
                        {loading === false && activatekey==="1" && <Histogram {...configcurrent} />}
                        {loading === false && activatekey==="2" && <Histogram {...configyesterday} />}
                        {loading === false && activatekey==="3" && <Histogram {...configlastweek} />}
                        {loading === false && activatekey==="4" && <Histogram {...configlastmonth} />}
                      </Col>
                    </Row>
                 </Modal>
              <Tabs activeKey={activatekey} onTabClick={(activeKey: string) => {lineData(activeKey);}}>
                <TabPane tab="Now" key="1"  disabled={loading}>
                  <div className="chart-srt">
                    {loading === true && <Spin size="large" />}
                    {loading === false && <Histogram {...configcurrent} />}
                  </div>
                </TabPane> 
                <TabPane tab="Yesterday" key="2"  disabled={loading}>
                  <div className="chart-srt">
                    {loading === true && <Spin size="large" />}
                    {loading === false && <Histogram {...configyesterday} />}
                  </div>
                </TabPane>
                <TabPane tab="Last Week" key="3"  disabled={loading}>
                  <div className="chart-srt">
                    {loading === true && <Spin size="large" />}
                    {loading === false && <Histogram {...configlastweek} />}
                  </div>
                </TabPane>   
                <TabPane tab="Last Month" key="4"  disabled={loading}>
                  <div className="chart-srt">
                    {loading === true && <Spin size="large" />}
                    {loading === false && <Histogram {...configlastmonth} />}
                  </div>
                </TabPane> 
              </Tabs>
            </div> 
            {pricesLastUpdate!=="" && <h5 className="pt-10">Price Last Updated: <b>{getDate(pricesLastUpdate)}</b></h5>}
        </div> 
      </Col>
      <Col xs={24} sm={24} md={24} lg={colwidthDyn}>
      <div className="chart-innr left chart-dist">
        <div className="chart-txt">
              <div className="chart-left">
                <h5>Price Distribution <span>Near floor</span></h5>
              </div>
          </div>
          <div className="chart-tab nw-dtab nw-pop">
          {loading === false &&  <div className="chart-slt">
                                    <span>Interval:</span>
                                    {binWidth2!="" && <Select value={binWidth2}
                                      style={{ width: 55 }}
                                      onChange={(binWidth2: string) => {handleChangefloor(binWidth2);}}>
                                        {winwidth > 1024 ?
                                              <>
                                        <Option value="0.5">0.5</Option>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        </> : <></>}
                                        <Option value="5">5</Option>
                                        <Option value="8">8</Option>
                                        <Option value="10">10</Option>
                                        <Option value="15">15</Option>
                                        <Option value="20">20</Option>
                                        <Option value="25">25</Option>
                                  </Select> }
                                 </div> }
                  <Modal title="PRICE DISTRIBUTION ALL" wrapClassName="chart-popup" width={1180} centered={true}  visible={isModalVisibletwo} closeIcon={<Image preview={false} onClick={()=>setisModalVisibletwo(!isModalVisibletwo)} src="../../images/close.svg" alt='Close' />} cancelButtonProps={{style:  {display: 'none'}}} okButtonProps={{style:  {display: 'none'}}}>
                    <Row className="popup-filter">
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {loading === true && <Spin size="large" />}
                        {loading === false && activatekey==="1" && <Histogram {...configcurrentnear} />}
                        {loading === false && activatekey==="2" && <Histogram {...configyesterdaynear} />}
                        {loading === false && activatekey==="3" && <Histogram {...configlastweeknear} />}
                        {loading === false && activatekey==="4" && <Histogram {...configlastmonthnear} />}
                      </Col>
                    </Row>
                 </Modal>
          <Tabs activeKey={activatekey} onTabClick={(activeKey: string) => {lineData(activeKey);}}>
                <TabPane tab="Now" key="1"  disabled={loading}>
                  <div className="chart-srt">
                  <Histogram {...configcurrentnear} /> 
                </div>
                </TabPane> 
                <TabPane tab="Yesterday" key="2"  disabled={loading}>
                  <div className="chart-srt">
                  <Histogram {...configyesterdaynear} />
                </div>
                </TabPane>
                <TabPane tab="Last Week" key="3"  disabled={loading}>
                  <div className="chart-srt">
                  <Histogram {...configlastweeknear} />
                </div>
                </TabPane>   
                <TabPane tab="Last Month" key="4"  disabled={loading}>
                  <div className="chart-srt">
                  <Histogram {...configlastmonthnear} />
                </div>
                </TabPane>
              </Tabs>
             
            </div>
            {pricesLastUpdate!=="" && <h5 className="pt-10">Price Last Updated: <b>{getDate(pricesLastUpdate)}</b></h5>}
        </div>
      </Col>
      </>
  )}

export default memo(Pricedistribution); 