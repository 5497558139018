import useLocalStorage from 'react-use-localstorage';
import { useState, useEffect, FC, memo} from 'react';
// import { Image } from 'antd';
// import { TwitterOutlined } from '@ant-design/icons';
import axios, {AxiosResponse} from 'axios';
import parse from 'html-react-parser';
import Connect2Phantom from '../Connect2Phantom';

interface FuncProps {
  //here you can declare the return type (here is void)
  onClose: (values: any) => void;
  darkMode: any;
}
const Topbar:FC<FuncProps> = (props: FuncProps) => {
   
  //const [Rates, setRates] = useState<any>([]);
  const [Marketdata, setMarketdata] = useState<any>([]); 
  const [Circulatingsupply, setCirculatingsupply] = useState<any>([]);
  const [Volume, setVolume] = useState<any>([]);
  const [Currentprice, setCurrentprice] = useState<any>([]);
  const [social, setSocial] = useState("");
  const [Currentpricechg, setCurrentpricechg] = useState(Number);
  const [high24, sethigh24] = useState<any>([]);
  const [low24, setlow24] = useState<any>([]);

   
    
    // const [dark, setDark] = useState<boolean>(theme);
    // console.log(currentMode);
      useEffect(() => { 
        getRates();
        getContent();
      }, []);


    // useEffect(() => {
    //   if (defaultDark) {
    //     setDark(true);
    //     sessionStorage.setItem("dark","true");
    //   } else {
    //     setDark(false);
    //     sessionStorage.setItem("dark","false")
    //   }
    // }, []);
   

     
    const numberFormat = (amount: any) => { 

      let showval = ""; 
      if(amount > 999 && amount < 1000000){
        showval =  (amount/1000).toFixed(2) + ' K'; // convert to K for number from > 1000 < 1 million 
      }else if(amount > 1000000 && amount < 1000000000){
        showval = (amount/1000000).toFixed(2) + ' M'; // convert to M for number from > 1 million 
      }else if(amount > 1000000000 && amount < 1000000000000){
        showval = (amount/1000000000).toFixed(2) + ' B'; // convert to M for number from > 1 million 
      }else if(amount < 1000){
        showval = amount;
      }
       return  showval; 
 
     } 

    const priceFormat = (amount: any) => {

      if(amount > 999 && amount < 1000000){
        return "$"+(amount/1000).toFixed(2) + ' K'; // convert to K for number from > 1000 < 1 million 
      }else if(amount > 1000000 && amount < 1000000000){
        return "$"+(amount/1000000).toFixed(2) + ' M'; // convert to M for number from > 1 million 
      }else if(amount > 1000000000 && amount < 1000000000000){
        return "$"+(amount/1000000000).toFixed(2) + ' B'; // convert to M for number from > 1 million 
      }else if(amount < 1000){
        return amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        }); // if value < 1000, nothing to do
      }
  

     } 

     
  const getContent = async () => {
  
    let info = ['social'] 
    await axios.get(process.env.REACT_APP_API_CMS+"cmsapi?type=block&info="+info).then(
      (res: AxiosResponse) => {        
        if(res.data.status==='success'){
        
          res.data.content.forEach((value: any) => { 
         
           if(value.static_block_uri==='social'){ 
              setSocial(value.static_block_content);
            }
           
          }); 

        }         
      }
    ).catch((e: Error) => { 
      console.log(e); 
    }); 
   
  };


const getRates = async () => {
  await axios.get(process.env.REACT_APP_API_COIN+"").then(
    (res: AxiosResponse) => {
    
      setMarketdata(res.data.market_data.market_cap.usd);
      setCirculatingsupply(res.data.market_data.circulating_supply);
      setVolume(res.data.market_data.total_volume.usd);
      setCurrentprice(res.data.market_data.current_price.usd);
      setCurrentpricechg(res.data.market_data.price_change_percentage_24h);
      //setRates(res.data.market_data);
      sethigh24(res.data.market_data.high_24h.usd);
      setlow24(res.data.market_data.low_24h.usd);
    }
  ).catch((e: Error) => {
    console.log(e);
  }); 
};

const styles = {
  green: {
    color: "#00ff00",
  },
  red: {
    color: "#fc2f48",
  }
};


const setshowModal = (show: boolean):void => {
}

const setshowConnectSuccess= (show: boolean):void =>{
  
}
const setshowConnectError = (show: boolean):void =>{
  
}
  // const setDynamicTheme = async (dark:any) => {
  //   console.log(dark);
  //   if(dark)
  //   {
  //     sessionStorage.setItem("dark",'false');
  //     setDark(false);
  //   }
  //   else
  //   {
  //     sessionStorage.setItem("dark",'true');
  //     setDark(true);
  
  //   }

  // }

 
  return(   
    <div className="pricing">
        <div className="wrapper">
        <div className="pricing-new">
          <ul>
          {/*<li>NFT COLLECTIONS: <span>149</span></li>*/}
            <li>24H PRICE HIGH: <span>{priceFormat(high24)}</span></li> 
            <li>24H PRICE LOW: <span>{priceFormat(low24)}</span></li>
            <li>SOL PRICE: <span>${Currentprice}</span></li> 
            <li>24H PRICE CHANGE: <span style={Currentpricechg >= 0?styles.green:styles.red}>{Currentpricechg >= 0?"+"+Currentpricechg.toFixed(1):Currentpricechg.toFixed(1)}%</span></li>
            <li>MARKET CAP: <span>{priceFormat(Marketdata)}</span></li>
            <li>VOLUME (24H): <span>{priceFormat(Volume)}</span></li>
            <li className="mob-link">CIRCULATING SUPPLY: <span>{numberFormat(Circulatingsupply)}</span></li>
            <li className="mob-link sap">
              <a href='/#mob-explore' onClick={props.onClose}>Explore Collections</a>
            </li>  
              
           
            <li className="mob-link"> 
              <a href="/sol-faq">FAQ</a>
            </li>

            <li className="mob-link">
              <a href='/sol-contact-us'>Contact Us</a>
            </li>
          </ul> 

        </div>
        <div className="social-new">
        {parse(social)}
                          
                    </div>
          {props.darkMode}
           {localStorage.getItem('logintoken')!=="" && localStorage.getItem('logintoken')!==undefined && localStorage.getItem('logintoken')!==null && <Connect2Phantom setshowConnectSuccess={setshowConnectSuccess} showconnect={false} showdisconnect={true} setshowModal={setshowModal} setshowConnectError={setshowConnectError}  /> }

  {/* <div className="usd"><span>USD</span>
  <Switch defaultChecked onChange={onChange} /></div> */}
 
        </div>
    </div> 
    );

  } 
 
export default memo(Topbar); 