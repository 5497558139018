import React, { useState, useEffect, FC, memo } from "react";
import axios, {AxiosResponse} from 'axios';
import { useParams } from 'react-router-dom';
import {  Tabs,Spin } from 'antd';
import { Line} from '@ant-design/charts';
import {convertTo12, getMax, getMin, getDateformat} from '../../helper'
import {CollectionContext} from "../index" 
import moment from 'moment';

const { TabPane } = Tabs;
const TokenChart:FC<{activeKey: any, setactivekey: any,loadingChart:any,setLoadingChart:any}> = ({children,activeKey,setactivekey,loadingChart,setLoadingChart}) => {
  const [loading, setloading] = useState(false);
  const { collectionID } = useParams(); 
  const [listdata, setListdata] = useState<any>([]);
  const [activatekey, setactivatekey] = useState("1");
      
  useEffect(() => {
      if(activatekey!==activeKey)
      lineData(activeKey);
     else
      lineData(activatekey);
  }, [activeKey]);

   const lineData = async (tabval: string) => {
    if(tabval!=="")
    {
      setloading(true); 
      setLoadingChart(true);

      setactivekey(tabval);
      setactivatekey(tabval);
      let URL = process.env.REACT_APP_API_BASE_URL+"collections/"+collectionID;
      if(tabval === "1"){
  
        URL += "/graphs/listed?filter[period]=day";
      }else if(tabval === "2"){
  
        URL += "/graphs/listed?filter[period]=week";
      }else if(tabval === "3"){
  
        URL += "/graphs/listed?filter[period]=month";
      }else if(tabval === "4"){
  
        URL += "/graphs/listed?filter[period]=all";
      }     
      

        await axios({ 
          method: 'get',
          url: URL,
          headers: { 
            'Authorization': 'Bearer '+localStorage.getItem("logintoken")
          }
        }).then(
        (res: AxiosResponse) => {
          setTimeout(()=>{   
            setloading(false); 
            setLoadingChart(false);
            setTokenChartData(res.data.data);
            //setListdata([...res.data.data].reverse());
          },1000);
        }).catch((e: Error) => {
        setTimeout(()=>{
        setloading(false); 
        setLoadingChart(false);
        },1000);  
        });  
    }
  }

  function setTokenChartData(data:any)
  {
    let tokenChartData:any = [];
    data.map((item:any)=>{
    let chartData:any;
    var date = moment(item.date).format("YYYY-MM-DDTHH:mm:ss.000")+'Z';
    var hour = moment(item.date).format("HH");
    chartData = {
      date:date,
      total_listed:item.total_listed,
      hour:hour
    }
    tokenChartData.push(chartData);
    }) 
    setListdata([...tokenChartData].reverse());
  }
  
  const tipColor = '#5b8ff9';

  let maxvalue = getMax(listdata,"total_listed");
  let minvalue = getMin(listdata,"total_listed");

  const config = {
      data: listdata, 
      height: 400,
      autoFit: true,
      xField: (activatekey==="1"?'hour':'date'),
      yField: 'total_listed', 
      yAxis: {
        minLimit: ((minvalue[0]?.total_listed !== undefined ? minvalue[0]?.total_listed : 0) - 1),
        maxLimit: ((maxvalue[0]?.total_listed !== undefined ? maxvalue[0]?.total_listed : 0) + 20),
        style: {
          fontSize: 12,
        },
        label: { 
          formatter: (v: string) => {
            return parseInt(v);
          },
          style: {
            fontWeight: 600, 
            padding: 4,
          }
        },
      }, 
      xAxis: {
        label: {
         rotate: -120,
          formatter: (v: string) => {
            return (activatekey==="1"?convertTo12(v)+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0":getDateformat(v));
          },
          style: {
            fontWeight: 600,
          }
        },
     
       
      },
      tooltip: {
        customContent: (title: any, items: any[]) => {
          let newtitle = (activatekey==="1"?convertTo12(title):getDateformat(title));

          const data = items[0]?.data || {};
          return `<div class="chart-tooltip"><h2>${newtitle}</h2><div><span style="background: ${tipColor}"></span><p>Total tokens listed: ${data.total_listed}</div></div>`;
        }
      },  
      point: {
        size: 5,
        shape: 'circle',
      },
      label: {
        style: {
          fill: '#aaa',
        },
      }, 
  };
  const {collection} = React.useContext(CollectionContext);
  
  const statichtml = (<div className="market-val">
  <div className="market-val-item">
  <div className="market-item full">
    <h6 className="no-color flx">
      <span className="mar-title">
        <span className="market-color-box"></span>
        Current
      </span> 
      <span className="market-txt"><span>{collection.listed_total}/{collection.supply_total}</span> ({collection.listed_percentage})</span>
    </h6>
  </div>
  </div>
  <div className="market-val-item">
  {loading === false && ( 
    <>
      <div className="market-item">
        <h6>
          <span className="mar-title">
            <span className="market-color-box max"></span>
            Max Value {activatekey === "1" && <>(1D)</> }  {activatekey === "2" && <>(1W)</> }  {activatekey === "3" && <>(1M)</> }  {activatekey === "4" && <>(All)</> } 
          </span> 
          <span className="market-txt">
            {maxvalue[0]?.total_listed}
          </span>
        </h6>
      </div>
      <div className="market-item">
        <h6>
          <span className="mar-title"> 
            <span className="market-color-box min"></span>
            Min Value {activatekey === "1" && <>(1D)</> }  {activatekey === "2" && <>(1W)</> }  {activatekey === "3" && <>(1M)</> }  {activatekey === "4" && <>(All)</> } 
          </span> 
          <span className="market-txt">
            {minvalue[0]?.total_listed}
          </span>
        </h6>
      </div>
    </>
  )}
  </div>
</div>)

  return (  
    <>
      <div className={`chart-tab nw-tab ${loading === true && 'dis-tab'}`}>
      <Tabs  activeKey={activatekey}  onTabClick={(activeKey: string) => { lineData((loading?"":activeKey)); }} >
                <TabPane tab="1D" key="1" disabled={loading}>
                <div className="chart-nw">
                  {statichtml} 
                <div className={`chart-srt ${loading === true && loadingChart && 'srt-load'}`}>
                    {loading === true &&  <Spin size="large" />}
                    {loading === false && <Line {...config} />}
                  </div>
                </div>
                </TabPane>
                <TabPane tab="1W" key="2" disabled={loading && loadingChart}>
                <div className="chart-nw">
                {statichtml} 
                <div className={`chart-srt ${loading === true  && loadingChart=== true && 'srt-load'}`}>
                    {loading === true  && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false  && loadingChart=== false && <Line {...config} />}
                  </div> 
                </div>
                </TabPane>
                <TabPane tab="1M" key="3" disabled={loading  && loadingChart}>
                <div className="chart-nw">
                {statichtml} 
                <div className={`chart-srt ${loading === true   && loadingChart=== true && 'srt-load'}`}>
                    {loading === true  && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false  && loadingChart=== false && <Line {...config} />}
                  </div>
                </div>
                </TabPane> 
                <TabPane tab="ALL" key="4" disabled={loading && loadingChart}>
                <div className="chart-nw">
                {statichtml} 
                <div className={`chart-srt ${loading === true && loadingChart=== true && 'srt-load'}`}> 
                    {loading === true && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false && loadingChart=== false && <Line {...config} />}
                  </div>
                </div>
                </TabPane>
              </Tabs> 
      </div>
    </>
  );
};

export default memo(TokenChart);
 