import { useState, useEffect, FC, memo} from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Tabs, Spin } from 'antd';
import { DualAxes} from '@ant-design/charts';
import {convertTo12, getDateformat} from '../../helper'
import moment from 'moment';
 
const { TabPane } = Tabs;
const Aggregatedchart:FC<{activeKey: any, setactivekey: any,loadingChart:any,setLoadingChart:any}> = ({children,activeKey,setactivekey,loadingChart,setLoadingChart}) => {
    const [loading, setloading] = useState(false);
    const { collectionID } = useParams();
     
    
    const [Listdataleft, setListdataleft] = useState<any>([]);
    const [Listdataright, setListdataright] = useState<any>([]);
    const [activatekey, setactivatekey] = useState("1");
       
    useEffect(() => {
        if(activatekey!==activeKey)
        lineData(activeKey);
       else
        lineData(activatekey);
    }, [activeKey]);

  const lineData = (tabval: string) => {
    if(tabval!="")
    {
      setloading(true);
      setLoadingChart(true);

      setactivekey(tabval);
      setactivatekey(tabval);
      let URL = process.env.REACT_APP_API_BASE_URL + "collections/" + collectionID+"/graphs/aggregates?filter[period]=";
      let URL_solana = process.env.REACT_APP_API_BASE_URL +"solana?filter[period]=";
  
      let period = "day";
      if (tabval === "2") {
        period = "week";  
      } else if (tabval === "3") {
        period = "month";
      } else if (tabval === "4") {
        period = "all";
      }
  
      

      let listed = axios({ 
        method: 'get',
        url: URL + period,
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("logintoken")
        }
      }); 
      let listedsolana = axios({ 
        method: 'get',
        url: URL_solana + period,
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("logintoken")
        }
      });
  

     

      axios.all([listed, listedsolana]).then(axios.spread((reslist,ressolana) => {
    
        let totalChartday1: { hour: any; value: any; type: string; }[] = [];
        let totalChartday2: { hour: any; value: any; name: string; }[] = [];
   
        reslist.data.data.map((value: any) => {
          
          var listdaydate = moment(value.date).format("YYYY-MM-DDTHH:mm:ss.000")+'Z';
          var listdayhour = moment(value.date).format("HH");


          let listday = {
            "hour": (tabval==="1"?convertTo12(listdayhour):getDateformat(listdaydate)),
            "value": value.total_listed,
            "name": "Listed Tokens", 
          }
          totalChartday2.push(listday); 

          var ownersdaydate = moment(value.date).format("YYYY-MM-DDTHH:mm:ss.000")+'Z';
          var ownersdayhour = moment(value.date).format("HH");

          
          let ownersday = {
            "hour": (tabval==="1"?convertTo12(ownersdayhour):getDateformat(ownersdaydate)),
            "value": value.total_owners,
            "name": "Owners",
          } 
          totalChartday2.push(ownersday);
        });
        reslist.data.data.map((value: any) => {
        
          var pricedaydate = moment(value.date).format("YYYY-MM-DDTHH:mm:ss.000")+'Z';
          var pricedayhour = moment(value.date).format("HH");

          let priceday = {
            "hour": (tabval==="1"?convertTo12(pricedayhour):getDateformat(pricedaydate)),
            "value": value.floor_price,
            "type": "Floor Price",   
          }
         
          totalChartday1.push(priceday);

          var solanadaydate = moment(value.date).format("YYYY-MM-DDTHH:mm:ss.000")+'Z';
          var solanadayhour = moment(value.date).format("HH");
  
          let solanaday = {
            "hour": (tabval==="1"?convertTo12(solanadayhour):getDateformat(solanadaydate)),
            "value": value.solana_price, 
            "type": "Solana Price",  
          }
          totalChartday1.push(solanaday);
          
        }); 
        
        ressolana.data.data.map((value: any) => {
         
     
          // let solanaday = {
          //   "hour": (tabval==="1"?convertTo12(value.hour):getDateformat(value.date)),
          //   "value": value.price, 
          //   "type": "Solana Price",  
          // }
          //totalChartday1.push(solanaday);
        });
        setTimeout(()=>{   
        setListdataleft(totalChartday1.reverse());
        setListdataright(totalChartday2.reverse());
        setloading(false);
        setLoadingChart(false);
        },1000);
       
      })).catch(errors => { 
        setTimeout(()=>{   
        setLoadingChart(false);
      },1000);
      });
    }

  } 


     
  
  const config = {
    data: [Listdataleft, Listdataright],
    xField:  "hour",
    yField: ['value', 'value'], 
    xAxis: {
      label: {
        rotate: -120,
        formatter: (v: string) => {
          return activatekey === "1"
          ? `${v}`+"\xa0\xa0\xa0\xa0\xa0\xa0"
          : `${v}`+"\xa0\xa0\xa0\xa0\xa0\xa0";
        },
        style: { 
          fontWeight: 600,
        },
        offsetY: 5,
      }, 
   
    },
    yAxis: {
       
      value: {
        offsetY: -10,
        label: {
          formatter: (v: any) => {
            return parseFloat(v).toFixed(2); 
          }, 
          style: {
            fontWeight: 600,
          },
        }
      }
    },
    legend:{},
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'type',  
        yAxis: {
          offsetX: 4,
          offsetY: 1.5,
        }
      },
      {
        geometry: 'line',
        seriesField: 'name',
        
         
      },
    ],
    quadrant: {},
  };

  config.legend = {flipPage: false, offsetY: -10, itemHeight: 20, maxHeight: 200};
  
  const statichtml = (<><p className="cnt-left">Price in SOL and USD</p>
                        <p className="cnt-right">Owners and Tokens</p></>)
 
  return(
      <>
         
       <div className="chart-innr main-chart">
         <><div className="chart-txt">
              <div className="chart-left">
                <h5>Aggregated Chart</h5>
              </div>
            </div> 
            <div className={`chart-tab nw-tab ${loading === true && loadingChart=== true && 'dis-tab'}`}>
              <Tabs   activeKey={activatekey} onTabClick={(activeKey: string) => { lineData((loading?"":activeKey)); }}> 
                <TabPane tab="1D" key="1"  disabled={loading && loadingChart=== true}>
               
                <div  className={`chart-srt chart-cnt chart-trans chart-agg ${loading === true && loadingChart=== true && 'srt-load'}`}>
                {loading === true && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false && loadingChart=== false && 
                  <>{statichtml}
                  <DualAxes {...config} /></>}
                </div> 
                </TabPane>
                <TabPane tab="1W" key="2"  disabled={loading && loadingChart=== true}>
                <div  className={`chart-srt chart-cnt chart-trans chart-agg ${loading === true && loadingChart=== true && 'srt-load'}`}>
                {loading === true && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false && loadingChart=== false && 
                  <>{statichtml}
                  <DualAxes {...config} /></>}
                </div>
                </TabPane>  
                <TabPane tab="1M" key="3"  disabled={loading && loadingChart=== true}>
                <div  className={`chart-srt chart-cnt chart-trans chart-agg ${loading === true  && loadingChart=== true && 'srt-load'}`}>
                {loading === true && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false && loadingChart=== false && 
                  <>{statichtml}
                  <DualAxes {...config} /></>}
                </div>
                </TabPane>
                <TabPane tab="ALL" key="4"  disabled={loading && loadingChart=== true}>
                <div  className={`chart-srt chart-cnt chart-trans chart-agg ${loading === true && 'srt-load'}`}>
                {loading === true  && loadingChart=== true &&  <Spin size="large" />}
                    {loading === false  && loadingChart=== false && 
                  <>{statichtml} 
                    <DualAxes {...config} /></>}  
                  </div>
                </TabPane>
              </Tabs> 
            </div></>
        </div>
      </>
  )}

export default memo(Aggregatedchart);   