import React, { useState, useEffect, FC, memo } from 'react';
import ReactGA from 'react-ga';
import axios, { AxiosResponse } from 'axios';
import { Layout, Image, Button, Anchor, Drawer, Select, Spin, Modal, Typography, Alert } from 'antd';
import { MenuOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import './header.less';
import Topbar from "./topbar";
import Connect2Phantom from '../Connect2Phantom';

const { Header } = Layout;
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;
//const { SubMenu } = Menu; 

interface FuncProps {
  toggleMode: any;
}
const Headerc: FC<FuncProps> = (props: FuncProps) => {


  ReactGA.initialize('UA-219413472-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [visible, setVisible] = useState(false);

  let [collection, setCollection] = useState<any>([]);
  let [collectiontemp, setCollectiontemp] = useState([]);
  let [isHomePage, setisHomePage] = useState<any>((window.location.pathname === "/") ? false : true);
  
  
  const [filterenable, setFilterenable] = useState(0);
  const [searchText, setSearchText] = useState(""); 

  const [loading, setloading] = useState(true);

  const [imageLoaded, setImageLoaded] = useState(false);

  const showDrawer = () => { 
    setVisible(true);
  };

  const closeContact = () => {
    let bodyclassr = document.getElementById("slidebar");

    if (bodyclassr !== null) {
      bodyclassr.classList.remove("sidebar-open");

    }
  }


  const onClose = () => {

    setVisible(false);
  };

  useEffect(() => {
    getData();
  }, []);

  

  const getData = async () => {
    setloading(true);   

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + "collections/summary",
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {

        if(res.data.data.success===false){
          window.location.href = '/login';
        }
        setloading(false); 
        setCollection(res.data.data);
        setCollectiontemp(res.data.data);
      }
    ).catch((e: Error) => {
      localStorage.removeItem('logintoken');
      window.location.href = '/login';
      console.log(e);
    });
  };


  // handle change event of search input
  const handleChange = (value: any) => {

    setSearchText(value);
    filterData(value);


  };


  const filterData = async (value: any) => {

    const lowercasedValue = value.toLowerCase().trim();
    setFilterenable(1);
    let result: any = [];
    console.log(lowercasedValue);
    console.log(lowercasedValue.length);
    if (lowercasedValue.length > 0) {
      await searchSummary(lowercasedValue);
    } else {
      getData();
    }
  };


  const searchSummary = async (value: any) => {    

    await axios({ 
      method: 'get',
      url: process.env.REACT_APP_API_BASE_URL + `collections/search?q=${value}`,
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("logintoken")
      }
    }).then(
      (res: AxiosResponse) => {
        setCollection(res.data.data);
      }
    ).catch((e: Error) => {
      console.log(e);
    });

  }


 
  if(localStorage.getItem("logintoken")==="" || localStorage.getItem("logintoken")===null || localStorage.getItem("logintoken")===undefined ){
    window.location.href = '/login';
  }else{

  return (<>
    <Topbar onClose={onClose} darkMode={props.toggleMode} />
    <Header className="solo-head" >
      <div className="wrapper">
        <div className="logo">
          <Link to="/">
            <Image src={"/images/logo-new.png"} preview={false} onLoad={() => setImageLoaded(true)} />
            {imageLoaded && <span>by Monkey Baby Business</span>}
          </Link>
        </div>
        {isHomePage && <div id="drp-box1" className="desk-sech">
          <Select className={`search-int ${loading ? 'load' : ''}`} showSearch showArrow={false} notFoundContent={(loading ? <><div className="spin"><Spin size="large" /></div></> : <><div className="drp-flx no-data">
            <Image preview={false} src={"/images/no-image.jpg"} />
            <span>No results found</span>
          </div></>)} getPopupContainer={() => document.getElementById("drp-box1")} style={{ width: '100%' }} virtual={true} placeholder="Search Collections" onSearch={(e: any) => handleChange(e)}  >
            {collection.map((item: any, index: any) => {
              return (<Option value={item.name} key={index + 100}>
                <div className="drp-flx" onClick={() => { window.location.href = "/overview/" + item.slug }}>
                  <Image preview={false} placeholder={<Image preview={false} src={item.image} />} src={item.image} />
                  <span>{item.name}</span>
                </div>
              </Option>)
            })}

          </Select>
          <SearchOutlined />
        </div>}
        <div className="desk-nav">
          <ul>
            <li >
              <NavHashLink to='/#explore' >Explore Collections</NavHashLink>
            </li>

            <li>
              <a href="/sol-faq">FAQ</a>
            </li>

            <li >
              <a href='/sol-contact-us'>Contact Us</a>
            </li>
          </ul>
        </div>

        <div className="sidemenu">
          <button type="button" className="ant-btn ant-btn-circle ant-btn-icon-only close-menuicon" onClick={closeContact}>
            <CloseOutlined />
          </button>
        </div>
        <div className="mobile-view-nav">
          <Button type="primary" onClick={showDrawer}>
            <Image preview={false} src={"../../images/open-menu-sol.png"} />
            <div className="topbar-anchor-img">
              <MenuOutlined /></div>
          </Button>
          <Drawer placement="left" onClose={onClose} visible={visible}>

            <Anchor>
              <div className="pricing pricing-mobile">
                <div className="pricing-new-mobile">

                  <div id="drp-box">
                    <Select className={`search-int ${loading ? 'load' : ''}`} showSearch showArrow={false} notFoundContent={(loading ? <><div className="spin"><Spin size="large" /></div></> : <><div className="drp-flx no-data">
                      <Image preview={false} src={"../images/no-image.jpg"} />
                      <span>No results found</span>
                    </div></>)} getPopupContainer={() => document.getElementById("drp-box")} style={{ width: '100%' }} virtual={true} placeholder="Search Collections" onSearch={(e: any) => handleChange(e)}  >
                      {collection.map((item: any, index: any) => {
                        return (<Option value={item.name} key={index + 100}>
                          <div className="drp-flx" onClick={() => { window.location.href = "/overview/" + item.slug }}>
                            <Image preview={false} placeholder={<Image preview={false} src={item.image} />} src={item.image} />
                            <span>{item.name}</span>
                          </div>
                        </Option>)
                      })}

                    </Select>
                  </div>
                  <Topbar onClose={onClose} darkMode={props.toggleMode} />
                </div>

              </div>

            </Anchor>
          </Drawer>
        </div>


      </div>
    </Header>    
  </>);
  }

}

export default memo(Headerc);   