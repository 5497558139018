import { useState, useEffect, FC, memo } from 'react';
import axios, {AxiosResponse} from 'axios';
import { Layout, Image, BackTop, Button } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
//import { Link } from 'react-router-dom'; 
const { Footer } = Layout;


const Footerc:FC = () => {

  const [Footerlogotag, setFooterlogotag] = useState("");
  const [Footercontactbutton, setFootercontactbutton] = useState("");
  const [disclaimer, setdisclaimer] = useState("");
  const [copyright, setcopyright] = useState("");
  const [help, sethelp] = useState("");
  const [services, setservices] = useState("");
  const [social, setSocial] = useState("");
  const [acceptcookie, setacceptcookie] = useState(false);

  const [imageLoaded, setImageLoaded]= useState(false);

  useEffect(() => {  
     getCookie("solobserverac"); 
     getContent(); 
  }, []);
     

  const getContent = async () => {
  
    let info = ['copy-right','services','help','disclaimer','footer-logo-tag','contact-us-button','social'] 
    await axios.get(process.env.REACT_APP_API_CMS+"cmsapi?type=block&info="+info).then(
      (res: AxiosResponse) => {        
        if(res.data.status==='success'){
        
          res.data.content.forEach((value: any) => { 
         
            if(value.static_block_uri==='copy-right'){ 
              setcopyright(value.static_block_content);
            }else if(value.static_block_uri==='services'){ 
              setservices(value.static_block_content);
            }else if(value.static_block_uri==='help'){ 
              sethelp(value.static_block_content); 
            }else if(value.static_block_uri==='disclaimer'){ 
              setdisclaimer(value.static_block_content);
            }else if(value.static_block_uri==='footer-logo-tag'){ 
              setFooterlogotag(value.static_block_content);
            }else if(value.static_block_uri==='contact-us-button'){ 
              setFootercontactbutton(value.static_block_content);
            }else if(value.static_block_uri==='social'){ 
              setSocial(value.static_block_content);
            }
           
          }); 

        }         
      }
    ).catch((e: Error) => { 
      console.log(e); 
    }); 
   
  };

   

  const createCookie = async (cookieName: string, cookieValue: string, cookieExpireDays: number) => {

    let currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + (cookieExpireDays*24*60*60*1000));
    let expires = "expires=" + currentDate.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    setacceptcookie(true);

  };  
  const getCookie = async (cookieName: string) => {

    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookiearray = decodedCookie.split(';');
    let value="";
    for(var i=0; i<cookiearray.length; i++) {
      name = cookiearray[i].split('=')[0];
      value = cookiearray[i].split('=')[1];
      if(value==="Yes"){
        setacceptcookie(true);
      }
       
   }
    


  };    


  return(<Footer>

    {acceptcookie === false &&
      <div className="cookies-container">
      <div className="wrapper">
        <div className="cookies-cont">  
          <div className="cookies-par">
            <p>By using our website, you agree to the use of cookie as described in our <a href="https://www.solobserver.com/cookies">Cookies Policy</a>
            <Button className="accept-btn" onClick={() => {createCookie("solobserverac","Yes",30);}}>Accept</Button>
            </p>
          </div>
          <Image src='/images/close.svg' preview={false} onClick={()=> setacceptcookie(true)} className="clost-btn" alt='close' />
        </div>
      </div>
    </div>} 
    <div className="wrapper">
    <div className="footer-wrapper">
        <div className="footer-columns">
            <div className="footer-lhs">
            <a href="/" className="foot-logo">
              <Image  preview={false} src={"/images/logo-new.png"} onLoad={()=> setImageLoaded(true)}/>
              {imageLoaded &&  <span>by Monkey Baby Business</span> }
            </a>
              {parse(Footerlogotag)}  
                 
            </div>
            <div className="middle-footer">
            {parse(Footercontactbutton)}
            </div>
            <div className="footer-rhs">
            {parse(services)}
            {parse(help)}
              </div> 
        </div> 

        <div className="footer-bottom">
            <div className="footer-second-top">
            <div className="footer-lhs">
                
                {parse(copyright)}
                <div className="social">
                    {parse(social)}
                </div>
            </div>
            </div>
            <div className="footer-rhs">
            <div className="middle-footer">
            {parse(disclaimer)}
            </div>
            </div>
        </div>
    </div>
    <div className="scroll">
    <BackTop visibilityHeight={50}>
      <div><ArrowUpOutlined /></div> 
    </BackTop>
    </div>
    </div>
</Footer>); 

  } 

export default memo(Footerc);    